.dark-theme {
  --bg-clr: #121212;
  --para-clr: #fff;
  --bg-box-clr: rgba(81, 81, 81, 0.4);
  --bg-box-clr-2: rgb(53, 53, 53);
  --bg-profile-box-clr: rgba(81, 81, 81, 0.4);
  --border-clr: #fff;
  --box-shadow-clr: rgba(0, 0, 0, 0.25);
  --profile-setting-tab-clr: rgb(191, 191, 191, 0.15);
  --profile-notification-switch: rgba(81, 81, 81, 0.4);
  --secondary-para-clr: white;
  --background-nft-card: rgb(74, 74, 74);
  --background-collection-filter: rgba(246, 246, 246, 0.8);
  --background-logo-color: #212529;
  --card_bg: #212529;
  --border-color-input: white;
  --border_clrs: rgba(255, 255, 255, 0.12);
  --border_hover_clrs: rgba(168, 168, 168, 0.12);
  --bd_clrs: white;
  --yellow_clr: #fec200;
  --bg_home: rgb(19, 19, 19) 5%;
  --borders_clr: #3d3d3d;
  --bg_home_image: rgba(49, 49, 49, 0.682);
  --header_bg: #121212;
  --opp: black;
}

.light-theme {
  --bg-clr: #fff;
  --opp: white;
  --card_bg: white;
  --para-clr: #1b1b1b;
  --bg-box-clr-2: rgb(255, 255, 255);
  --secondary-para-clr: #6a6a6a;
  --bg-box-clr: auto;
  --border-clr: #212529;
  --box-shadow-clr: rgba(0, 0, 0, 0.25);
  --profile-setting-tab-clr: rgb(254, 194, 0, 0.15);
  --bg-profile-box-clr: #f6f6f6;
  --profile-notification-switch: #f6f6f6;
  --background-nft-card: #f6f6f6;
  --background-collection-filter: rgba(246, 246, 246, 0.5);
  --background-logo-color: #edededb5;
  --border-color-input: rgb(229, 232, 235);
  --border_clrs: rgba(134, 134, 134, 0.12);
  --border_hover_clrs: rgba(61, 60, 60, 0.12);
  --bd_clrs: rgba(0, 0, 0, 0.5);
  --yellow_clr: #184779;
  --borders_clr: #e5e8eb;
  --bg_home: rgb(255, 255, 255) 5%;
  --bg_home_image: rgba(0, 0, 0, 0.682);
  --header_bg: linear-gradient(0deg, var(--bg_home), rgba(0, 0, 0, 0.299) 60%), var(--bg_home_image);
}

.b-r-1 {
  border-right: 1px solid var(--border_clrs) !important;
}

.opp_clrs {
  color: var(--opp) !important;
}

.bg_yellow_clr {
  background-color: #fec200 !important;
}

a.help.active div {
  background-color: var(--yellow_clr);
  color: var(--bg-clr) !important;
  padding: 1rem !important;
}

.bg_yellow {
  background-color: var(--yellow_clr) !important;
}

.bg-box-clr-2 {
  background-color: var(--bg-box-clr-2) !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

input {
  color: var(--para-clr) !important;
}

.yellow_clr {
  color: var(--yellow_clr) !important;
}

.card_bg {
  color: var(--card_bg) !important;
}

.f_para_clr {
  fill: var(--para-clr) !important;
}

.search_global {
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--border_clrs);
  backdrop-filter: blur(20px);
  transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
    border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;

  &:hover {
    border-right-width: 1px;
    border-color: transparent rgba(255, 255, 255, 0.2) transparent transparent;
    background-color: var(--border_hover_clrs);
  }
}

.search_global_ck {
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 0.12);

  backdrop-filter: blur(20px);
  transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
    border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;

  &:hover {
    border-right-width: 1px;
    border-color: transparent rgba(255, 255, 255, 0.2) transparent transparent;
    background-color: rgba(255, 255, 255, 0.2);

  }
}

/* *{
  border-color: var(--border_clrs) !important;
      background-color: rgba(255, 255, 255, 0.12);
} */
input,
input.form-control,
button#input-group-dropdown-2 {
  border-color: var(--borders_clr) !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.b-g-c {
  background-color: var(--background-logo-color);
}

.bg-clr {
  backdrop-filter: blur(60px);
  background: linear-gradient(0deg, var(--bg_home), rgba(0, 0, 0, 0.299) 60%), var(--bg_home_image);
}


.m-r-0 {
  margin-right: 0rem !important;
}

.d-f {
  display: flex !important;
}

.w-1 {
  width: 1rem !important;
}

.m-r-1 {
  margin-right: 1rem !important;
}

.m-t-1 {
  margin-top: 1rem !important;
}

.p-x-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.p-y-0_5 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.pa-2 {
  padding: 2rem !important;
}

.j-c-s-b {
  justify-content: space-between !important;
}

.f-s-0_8 {
  font-size: 0.8rem !important;
}

.m-l-1 {
  margin-left: 1rem !important;
}

.b-r-10 {
  border-radius: 10px !important;
}

.pa-0_5 {
  padding: 0.5rem !important;
}

.Section {
  margin: 7rem auto;
}

.Table-Section {
  margin: 2rem 0rem;
}

section {
  background-image: linear-gradient(to right, #47474a, #73525c);
}

body {
  background-color: var(--bg-clr) !important;
  color: var(--para-clr) !important;
}


* {
  input {
    border-color: var(--bd_clrs) !important;
  }
}

button {
  border-color: rgb(226, 226, 226) !important;
}

body {
  overflow-x: hidden;
}

h1 {
  /* font-family: "Futura Maxi CG Bold"; */
  font-style: normal;
  font-weight: 600;
  font-size: 3rem !important;
  line-height: 42px;
  color: var(--para-clr);
}

h2 {
  /* font-family: "Futura Maxi CG Bold"; */
  font-style: normal;
  font-weight: 400;
  font-size: 2.5rem !important;
  line-height: 66px;
  color: var(--para-clr) !important;
}

.button-primary {
  width: 155px;
  height: 56px;
  border-radius: 15px;
  border: none !important;
  font-family: "Century Gothic" !important;
  font-style: normal;
  font-weight: 800;
  font-size: 16px !important;
  line-height: 20px;
  align-items: center;
  color: #101010;
}

textarea:focus {
  outline: none !important;
  color: var(--para-clr) !important;
}

#button-1 {
  background: #e6e6e6;
  border-radius: 15px;
  border: none !important;
  font-family: "Century Gothic" !important;
  font-style: normal;
  font-weight: 900 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  align-items: center;
  color: #101010 !important;
  padding: 18px 48px !important;
  text-decoration: none !important;
}

.button-secondary {
  width: 155px;
  height: 56px;
  background: #fec200;
  border-radius: 15px;
  border: none !important;
  /* font-family: "Century Gothic" !important; */
  font-style: normal;
  font-weight: 800;
  font-size: 16px !important;
  line-height: 20px;
  align-items: center;
  color: #101010;
}

#button-2 {
  background: #fec200;
  border-radius: 15px;
  border: none !important;
  /* font-family: "Century Gothic" !important; */
  font-style: normal;
  font-weight: 900 !important;
  font-size: 16px !important;
  line-height: 20px;
  align-items: center;
  color: #101010;
  text-decoration: none;
  padding: 18px 60px !important;
}

h3 {
  /* font-family: "Futura Maxi CG Bold" !important; */
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1.5rem !important;
  align-items: center;
  color: var(--para-clr) !important;
  margin-top: 50px !important;
  margin-bottom: 1.5rem !important;
}

.h4 {
  /* font-family: "Futura Maxi CG Bold"; */
}

* {
  font-family: 'Inter', sans-serif !important;
}

p {
  font-family: "Futura Maxi CG Book";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  align-items: center;
}

.navbar {
  padding: 1rem 1%;
}

#table-header {
  /* font-family: "Futura Maxi CG Bold"; */
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  align-items: center;
}

span.nftname {
  margin-right: 0.5rem;
  /* font-family: "Futura Maxi CG Bold"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  color: var(--para-clr);
  margin-right: 5px;
}

img.tableimg {
  margin-right: 2rem;
}




span.s-no {
  margin-right: 2rem;
  /* font-family: "Futura Maxi CG Bold"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  color: var(--para-clr);
}

span.nftamount {
  /* font-family: "Futura Maxi CG Bold"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  color: var(--para-clr);
}

#data-table {
  /* font-family: "Futura Maxi CG Bold"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  color: var(--para-clr);
}

#footer-posted-margin-bottom {
  margin-bottom: 3rem;
}

.card-body {
  background-color: var(--bg-box-clr);
  color: var(--para-clr);
}

thead,
tbody,
tfoot,
tr,
td,
th,
.Hometable {
  background-color: var(--bg-clr);
  color: var(--para-clr);
}

.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-left: 0px !important;
}

.card {
  background-color: var(--bg-clr) !important;
}

.bg_clr {
  background-color: var(--bg-clr) !important;
}

.css-1u20msc {
  margin-top: 0px !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: transparent !important;
}

.css-isbt42>.MuiGrid-item {
  padding-left: 0px !important;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignRight.MuiTableCell-sizeMedium.css-1sgp6db {
  color: var(--para-clr);
}

.css-1bigob2 {
  color: var(--para-clr) !important;
}

.css-1sgp6db {
  text-align: left;
}

.css-13xy2my {
  box-shadow: none !important;
}

.css-1sgp6db {
  text-align: left !important;
}

.css-13xy2my {
  background-color: none !important;
}

@media screen and (max-width: 768px) {
  #footer-posted-margin-bottom {
    margin-bottom: 2.5rem;
  }
}

/* // --------------responsive---------------- */

html {
  font-size: 100%;
}

@media screen and (max-width: 1536px) {
  html {
    font-size: 95% !important;
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 90% !important;
  }
}

@media screen and (max-width: 1050px) {
  html {
    font-size: 85% !important;
  }
}

@media screen and (max-width: 900px) {
  html {
    font-size: 80% !important;
  }
}

@media screen and (max-width: 750px) {
  html {
    font-size: 75% !important;
  }
}

@media screen and (max-width: 600px) {
  html {
    font-size: 70% !important;
  }
}


@media screen and (max-width: 600px) {
  #button-1 {
    padding: 12px 35px;
  }

  #button-2 {
    padding: 12px 40px;
  }
}

@media screen and (max-width: 414px) {
  #button-1 {
    padding: 8px 25px;
  }

  #button-2 {
    padding: 8px 25px;
  }
}

.filter-button,
.refresh-button {
  width: 100% !important;
}

.wallet {
  color: var(--para-clr) !important;
}

.bg_blue {
  background-color: blue !important;
}