.search-00 {
    width: 580px;
    height: 48px;
    border: 1px solid #C5C5C5;
    border-radius: 15px !important;
}

.profile-recently {
    height: 48px !important;
    background: rgba(246, 246, 246, 0.5) !important;
    border-radius: 15px !important;
    font-family: 'Futura Maxi CG Book' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 126%;

    border: none !important;
}


.view-bu {
    border-radius: 15px;
}

.vv-bu {
    background: var(--profile-notification-switch);
    border-radius: 5px;
    margin-right: 15px;
    padding: 3px;

}
.card-body-text.card-body {
    height: -10rem;
}
.unfill-heart-like{
    fill: red !important;
}
.fill-heart-like{
    fill: red !important;
}
.d-f{
    display: flex !important;
}
.j-c-s-b{
    justify-content: space-between !important;
}
.a-i-c{
    align-items: center !important;
}

.card-head {
    /* font-family: 'Futura Maxi CG Book' !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 15px !important;
}

.profile-card{
    width: 100% !important;
}

@media screen and (max-width:650px) {
    .MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller {
        overflow: scroll !important;
    }
}



/* PROFILE SELECTOR */
.add-cart-section{
    background: #2081E2;
    padding: 10px;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: none;
    cursor: pointer;
    bottom: 0;
    width: 100%;
    position: absolute;
    left: 0;
}
.profile-card.card{
    position: relative;
    /* height: 430px; */
    /* padding-bottom: 3rem !important; */
}

.profile-card.card:hover .add-cart-section{
    display: block;
}
.add-cart-section:hover{
    display: block;
}
.add-sec{
    font-size: 1rem;
    font-family: "Poppins";
    font-weight: 800;
    color: #fff;
    /* margin-left: 70px; */
}
.thunder{
    color: #fff;
    font-size: 1.4rem;
}
.buy-thunder{
    margin-right: 10px;
    font-size: 1.5rem;
}
.buy{
    display: none;
}
.p-y-7{
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
}
.g-c{
    color: grey !important;
}