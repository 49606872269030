.background-resources {
    background-image: url(../Images/Resourceshelpcenter-img.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 485px;
}

.text-cont {
    position: absolute;
    left: 3%;
    bottom: 5%;
    transform: translate(2%, 2%);
}

.origin-para {
    font-family: 'Futura Maxi CG Book';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 20px;
    align-items: center;
    text-align: center;
}

.origin-sections {
    padding-top: 10rem;
    text-align: center;
}

.origin-part {
    font-family: 'Futura Maxi CG Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 47px;
}

.hhumjk {
    width: 100%;
    height: 15rem;
    background: rgba(241, 241, 241, 0.4);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}

.partners {
    font-family: 'Futura Maxi CG Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 47px;
    text-align: center;
}

.text111 {
    font-family: 'Futura Maxi CG Bold';
    font-style: normal;
    font-weight: 400;

    line-height: 15px;
    align-items: center;
    display: flex;
    margin-top: 15px;
}

.place112 {
    font-family: 'Futura Maxi CG Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 35px;
    color: #000000;
}

.place1124 {
    font-family: 'Futura Maxi CG Book';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem !important;
    line-height: 20px;
}

button#dropdown-basic\ recently-listed-dropdown {
    color: var(--para-clr);
}

.ceo-info {
    font-family: 'Futura Maxi CG Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 25px;
}

.ceo-image {
    width: 100%;
}

.welcome-text {
    color: white !important;
}