.nav-list,
.nav-icon {
  display: flex;
  list-style-type: none;
  float: right;
  font-size: 2rem;
  color: var(--white);
  font-weight: 600;
  margin-top: 10px;
}

.bg_black_clrs {
  background-color: rgba(0, 0, 0, 0.477) !important;
}

.bg_black_clrs_search {
  background-color: rgba(88, 88, 88, 0.34) !important;
}

span.newlogoname {
  font-family: "Futura Maxi CG Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 26px;
  background: linear-gradient(94.08deg, #56d270 21.91%, #ecd879 77.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-left: 1.5rem;
}

.pa-9px {
  padding: 9px !important;
}

.pa-10px {
  padding: 10px !important;
}

.f-s-20px {
  font-size: 20px !important;
}

.w-10 {
  width: 10rem !important;
}

.nav-list li,
.nav-icon li {
  margin-right: 2rem;
  font-size: 1.2rem;
  color: var(--white);
  font-weight: 600;
  color: white !important;
}

.origin-logo {
  margin-top: 11px;
}

.logo {
  margin-left: 1.5rem;
}

.offcanvas-logo {
  padding-inline-start: 0;
}

.navbar {
  position: fixed !important;
  top: 0;
  z-index: 2;
  background-image: url();
}

.toggle-btn {
  float: right;
  font-size: 2rem !important;
}

.dropdown-item {
  font-family: "Futura Maxi CG Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  align-items: center;
}

.li-section {
  border-bottom: 0.5px solid rgb(169, 168, 168);
  padding: 5px 0px;
  margin-right: 0px !important;
}

.ic {
  margin-right: 1rem;
}

.explore-list-section {
  margin-right: 0.7rem;
}

.discord {
  font-size: 25px;
}

.offcanvas-logo {
  background-color: var(--background-logo-color);
}

.tele {
  font-size: 25px;
}

.face {
  color: rgb(6, 6, 6);
  font-size: 25px;
}

.insta {
  font-size: 25px;
}

.twitter {
  font-size: 25px;
}

.tik {
  font-size: 25px;
}

.cart-menu {
  font-family: "Futura Maxi CG Bold" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 10px !important;
}

.para-li {
  font-family: "Futura Maxi CG Book" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 10px !important;
  line-height: 15px;
  align-items: center;
  margin-left: 10px;
}

.dropdown-menu.card-section {
  margin-left: -180px;
  width: 200px;
  height: 220px;
}

.imgback {
  width: 198px;
}

.dropdown-menu.card-section.image-bg {
  background-image: url("./Navimg/Rectangle 684.png");
  background-repeat: no-repeat;
}

.form-switch {
  padding-left: 1.5rem !important;
}

.navbar {
  background-color: rgba(166, 166, 166, 0.5);
}

.navbar.colorChange {
  background-color: rgba(28, 28, 28, 0.8);
}

#list-text {
  color: white !important;
}

.card-section-button {
  height: 32px;
  left: 39.34px;
  top: 267.78px;
  border-radius: 10px;
  font-size: 12px;
  margin-left: 25px;
  margin-top: 65px;
  border: none;
}

a.dropdown-item {
  margin: 0.3rem 0rem;
}

#offcanvas-button-toggle {
  background-color: transparent !important;
  border: none !important;
  font-size: 1.5rem;
  float: right;
  margin-right: 1rem;
}

.offcanvas-header .btn-close {
  font-size: 1rem;
}

.create-offcanvas a {
  color: black;
  font-size: 1rem;
}

.create-offcanvas a:active {
  color: black;
}

ul.dropdown-menu.show-profile.show {
  margin-left: -203px;
  padding: 0px;
}

.m-r-1 {
  margin-right: 1rem !important;
}

.ios-switch {
  margin-top: 0px !important;
}

.form-check.form-switch.text-light {
  padding-left: 0px;
}

.c-b {
  color: black !important;
}

.cart-box-section {
  width: 30%;
  height: 100%;
  background: white;
  border-radius: 15px;
}

.num-redco {
  border: 0.5px solid black;
  border-radius: 100px;
  font-size: 1rem;
  padding: 5px 10px 5px 10px !important;
  background: #fec200;
  border: none;
}

.loop-section {
  font-size: 1rem;
}

.amount-cart-item {
  font-size: 1rem;
  color: #535353;
  font-family: "Poppins";
  font-weight: 600;
  float: right;
}

.loop-section1 {
  font-size: 0.7rem;
  font-family: "Poppins";
  font-size: normal;
  font-weight: 400;
  color: #535353;
  line-height: 23px;
}

.beg-section {
  font-family: "Futura Maxi CG Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 23px;
  align-items: center;
  color: #000000;
}

.section-eth-amount {
  background: linear-gradient(130.18deg,
      rgba(188, 188, 188, 0.21) 54.86%,
      rgba(71, 71, 72, 0.12) 97.18%);
  border-radius: 15px;
  padding: 10px;
  display: flex;
}

.section-eth-amount1 {
  background: #000;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
}

.check-out11 {
  font-family: "Futura Maxi CG Book";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 126%;
  color: #ffffff;
  text-align: center;
}

.check-out122 {
  font-family: "Futura Maxi CG Book";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 126%;
  text-decoration-line: underline;
  text-align: center;
  color: #fec200;
}

.hhun {
  text-align: center;
}

.cor-dd {
  background: #fec200;
  border: 1px solid #fec200;
  border-radius: 100px;
  font-size: 7rem !important;
  color: #ffffff;
}

.purchse-com {
  font-family: "Century Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 126%;
  color: #fec200;
}

.pur-div {
  text-align: center;
  margin-top: 20px !important;
}

.item-se {
  font-family: "Century Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 126%;
  color: #202020;
}

.chek {
  text-align: center;
}

.icons-cross {
  margin-left: 130px;
}

.ethllo {
  text-align: end;
}

span.dropdown-item {
  margin: 0.3rem 0rem;
}

/* -----------------dropdown offcanvas-------------------------- */

.dropbtn-offcanvas-link1 {
  color: white;
  padding: 16px;
  font-size: 1rem;

  cursor: pointer;
  margin: 0.5rem 0rem;
  width: 100%;
  background-color: transparent;
  color: black;
  border: 1px solid;
}

.dropdown-offcanvas-link1 {
  position: relative;
  display: block;
}

.dropdown-content-offcanvas-link1 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-offcanvas-link1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 1rem;
}

.dropdown-content-offcanvas-link1 a:hover {
  background-color: #f1f1f1;
}

.dropdown-offcanvas-link1:hover .dropdown-content-offcanvas-link1 {
  display: block;
}

#profile_back {
  background-color: transparent !important;
}

.p-t-3px {
  padding-top: 2px !important;
}

.w-10 {
  width: 10rem !important;
}

.dropdown-offcanvas-link1:hover .dropbtn-offcanvas-link1 {}

.offcanvas {
  max-width: 95% !important;
}

.social_icons_offcanvas {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.inputtext-navbar {
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  color: white;
}

.inputtext-navbar::placeholder {
  color: white;
}

.search-nav {
  /* padding: 0.5rem; */
  border-radius: 10px;
}

.input-nav {
  display: flex;
}

.search-icon {
  margin-right: 1rem;
}

.css-1u80wl {
  background: transparent !important;
  width: 100% !important;
  border: 1px solid white !important;
  color: white !important;
  border-radius: 10px !important;
}

.css-mnn31 {
  color: white !important;
}

.css-vubbuv {
  color: white;
}

.connect-wallet-button-nav {
  border: none;
  background-color: #fec200;
  width: 100%;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.connect-wallet-button {
  padding: 20px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  fill: var(--para-clr) !important;
}

input.MuiInputBase-input.css-yz9k0d-MuiInputBase-input {
  color: white;
}

/* media query */

@media screen and (max-width: 1200px) {
  .navbar-desktop {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .toggle-btn {
    display: none;
  }
}

.css-vubbuv {
  fill: white !important;
}

svg#search-icon-nav {
  fill: white !important;
}

.home-button a {
  color: white !important;
}

@media screen and (max-width: 1200px) {
  .search-nav {
    padding: 0rem;
  }

  .search-text-field {
    margin-top: 0rem !important;
    margin-bottom: 3rem !important;
  }
}

.search-text-field {
  width: 100% !important;
}

@media screen and (max-width: 600px) {
  /* .search-text-field {
    padding-top: 1rem !important;
  } */
}

@media screen and (min-width: 600px) {
  .m-q-a-p-r-1_600 {
    padding-right: 1rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .m-q-a-p-r-1_1200 {
    padding-right: 1rem !important;
  }
}