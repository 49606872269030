.featured-head {
  font-family: "Futura Maxi CG Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
}

.para-featured {
  font-family: "ITC Avant Garde Gothic Std" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 120% !important;
  letter-spacing: 0.005em !important;
}

.create-bu {
  width: 178.87px;
  height: 53.4px;
  left: 307px;
  top: 327px;
  background: #fec200;
  border-radius: 9.92153px;
  border: none;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 126% !important;
  color: #000000 !important;
}

.view-section-button {
  width: 178.87px;
  height: 53.4px;
  left: 497px;
  top: 327px;
  border-radius: 10px;
  border: none;
  margin-left: 15px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 126% !important;
  color: #000000 !important;
}

.name-card {
  font-family: "Futura Maxi CG Bold" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 0px !important;
  display: flex !important;
  align-items: center !important;
}

.nnhi-card {
  font-family: "Futura Maxi CG Book";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
}

.card-create-section {
 

  background: var(--profile-notification-switch) !important;
  border-radius: 15px !important;
  margin-top: 10px !important;

}

.icons-featured {
  width: 40px;
  height: 41.38px;
  margin-top: 20px;
  border-radius: 8px;
  margin-left: 10px;
}

.horizonicon {
  margin-top: 20px;
  margin-left: 15px;
  color: #6a6a6a;
}

.images-sections-featured {
  float: right;
}

.cards-name-ddd {
  margin-top: 30px;
  margin-left: 12px;
}

.section-feature-content {
  margin-top: 40px;

}



.featured-save-button {
  width: 118.94px;
  height: 56px;
  background: #fec200;
  border-radius: 15px;
  border: none;
  font-family: "Century Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: #101010;
}

.section-button-featured {
  text-align: right;
  margin-top: 40px;
}



@media screen and (max-width: 782px) {


  .view-section-button {
    margin-left: 12px;
  }
}





@media screen and (max-width: 767px) {
  .section-button-featured {

    text-align: left;
  }
}