.background-collections-img {
  background-image: url(../../Images/Resourceshelpcenter-img.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 1440px;
  height: 485px;
}

.create-button {
  padding: 6px 22px !important;
}

.collection-section {
  padding-top: 180px;
  padding-left: 50px;
}

.mycoll-head {
  color: #fff;
}

.input_place::placeholder {
  padding-left: 2rem !important;
}

.modal-dialog {
  max-width: none !important;
}

.modal-content {
  width: 90% !important;
  margin: auto;
}

.c-w.card-body {
  color: var(--para-clr) !important;
}

@media screen and (max-width:1200px) {
  .nft-switch {
    display: block !important;
  }

  .m-q-b-m-y-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

}


.setting-collect {
  align-items: center !important;
  text-align: center !important;
  height: 30rem;
}

.pa-0 {
  padding: 0rem !important;
}

.img-drag-circle {
  border: 3px dashed #c5c5c5;
  text-align: center;
  border-radius: 50%;
  padding: 40px;
  max-width: 12rem;
}

input[type="checkbox"] {
  background-color: #fec200 !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #fec200 !important;
}

.tag-image-name-drop {
  /* font-family: "Futura Maxi CG Book"; */
  font-style: normal;
  font-weight: 400;
  font-size: 10px !important;
  line-height: 120%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #c5c5c5;
}

.highligh-section {
  /* font-family: "Futura Maxi CG Bold" !important; */
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 19px !important;

  margin: 15px 0px 15px 0px !important;
}

.content-form {
  /* font-family: "Futura Maxi CG Book" !important; */
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 15px !important;
  /* color: #6a6a6a !important; */
}

.featured-img-box {
  padding: 60px;
  border: 3px dashed #c5c5c5;
  border-radius: 30px;


}

.featured-img-box2 {
  padding: 60px;
  border: 3px dashed #c5c5c5;
  border-radius: 30px;
  width: 100%;
}

.fe-bo {
  text-align: center;
}

.css-1pv7cux {
  top: 50% !important;
  overflow-y: scroll !important;
  height: 100vh !important;
}

.mycollections-sc {
  overflow-y: scroll !important;
  overflow-x: none !important;
  height: 100vh !important;
  top: 50% !important;
}

.recordion-in {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--borders_clr);
  background-color: transparent !important;
}

.m-q-d-f {
  display: flex !important;
}

.m-q-w-13 {
  width: 13rem !important;
}

@media screen and (max-width:900px) {
  .m-q-d-f {
    display: block !important;
  }

  .m-q-w-13 {
    width: 7rem !important;
  }
}

.recordion-in::placeholder {
  /* font-family: 'Poppins' !important; */
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 126% !important;
  color: #C5C5C5 !important;
}

.button-section-nft {
  text-align: center;
  margin: 2rem 0rem;
}

.form-select:focus {
  border: 1px solid rgb(153, 153, 153) !important;
  box-shadow: none !important;
}

button#dropdown-basic {
  padding: 0.5rem !important;
}

img.card-img-top {
  height: 20rem !important;
}

textarea {
  padding: 1rem !important;
}

.dropdown-toggle::after {
  float: right;
  margin-top: 0.5rem;
}

.text-area-mycollections {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  height: 15vh;
  background-color: transparent !important;
  color: var(--para-color) !important;
}

.css-vamk89-MuiFormControl-root {
  margin: 0px !important;
}

.section-collection {
  margin: 3rem 0rem;
}

.f-s-1_5 {
  font-size: 1.5rem !important;
}

.f-w-900 {
  font-weight: 900 !important;
}

.f-s-2 {
  font-size: 2rem !important;
}

.f-r {
  float: right !important;
}

.cate-bu {
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  background: white;
  padding: 16px;
  /* font-family: 'Poppins' !important; */
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  line-height: 126% !important;
  color: #000000 !important;
}

.t-a-r {
  text-align: right !important;
}

.max-w-70 {
  max-width: 70rem !important;
}

.modal-dialog.modal-dialog-centered {
  max-width: 50rem !important;

}

.m-a {
  margin: auto !important;
}

.p-l-3 {
  padding-left: 3rem !important;
}

.t-a-c {
  text-align: center !important;
}

.m-t-8 {
  margin-top: 8rem !important;
}

.b-c-t {
  background-color: transparent !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
  color: inherit !important;
  color: black !important;
}

.dropdown-toggle::after {
  content: none !important;
}

.eth-bu-mycoll {
  background: "#fff" !important;
}

.b-r-5 {
  border-radius: 5px !important;
}

.f-w-600 {
  font-weight: 600 !important;
}

.p-x-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.b-1 {
  border: 1px solid rgb(122, 122, 122) !important;
}

#dropdown-basic-button {
  background: #fff;
  color: #000000;
  border: none;
}

.m-l-0 {
  margin-left: 0rem !important;
}

.m-y-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.MuiFormControl-root.css-vamk89-MuiFormControl-root {
  width: 100%;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px;
}

img.eth-icon {
  background: #E4E4E4;
  border-radius: 50px;
  padding: 5px;
  margin-right: 10px;
}

.eth-name {
  /* font-family: 'Poppins' !important; */
  font-style: normal;
  font-weight: 800 !important;
  font-size: 16px !important;
  line-height: 126% !important;
  color: #000000 !important;
}

.sp-et {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 126%;
  color: #000000;
}

.button-eth {
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 12px;
  background: #fff;
}

.eth-card {
  font-size: 16px;
  font-weight: 600;
  /* font-family: "Poppins"; */
}

.ios-check-button {
  float: right;
  margin-top: 20px;
}

.save-bu {
  background: rgba(228, 228, 228, 0.5);
  border-radius: 15px;
  padding: 1.3rem;
  border: none;
  color: #000000;
  /* font-family: 'Century Gothic'; */
  font-weight: 700;
  font-size: 17px;
}

.save-bu22 {
  background: #FEC200;
  border-radius: 15px;
  padding: 1.3rem;
  border: none;
  color: #000000;
  /* font-family: 'Century Gothic'; */
  font-weight: 700;
  font-size: 17px;
  margin-left: 10px;
}

.button-section {
  float: right;
  margin: 2rem 0rem;
}

.link-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #D9D9D9;
  border-top: none;
  border-bottom: none;
}

.link-input::placeholder {
  padding: 30px;
}

.link-input1 {
  width: 100%;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #D9D9D9;
}

.link-input1::placeholder {
  padding: 30px;
}

.link-input3 {
  width: 100%;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #D9D9D9;
}

.link-input3::placeholder {
  padding: 30px;
}

.icons-input {
  margin: 10px 10px;
}

#create-collection-button {
  background: #FEC200 !important;
  border-radius: 10px;
  /* font-family: 'Century Gothic'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  padding: 0.8rem 1rem;
}

button.cate-bu {
  color: inherit !important;
  border-color: inherit !important;
}

input.form-control {
  color: inherit !important;
}

.m-y-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

img.profile-setting-img {
  height: 13rem;
  width: 13rem;
  border-radius: 50%;
  margin-bottom: 2rem;
}