img.card-img-top {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.boxes {
  border: 1px solid #e5e8eb;
  padding: 10px 0px 0px 0px;
  border-radius: 6px;
}

.bbb {
  border-bottom: 1px solid #e5e8eb;
  padding: 15px;
}

th,
tr,
td {
  background-color: white !important;
}

.t-a-l {
  text-align: left !important;
}

input {
  color: var(--para-clr) !important;
  background-color: transparent !important;
}

.ant-picker.css-dev-only-do-not-override-1km3mtt.w-100 {
  background-color: transparent !important;
}


@media screen and (max-width:1200px) {
  .m-q-d-b {
    display: block !important;
  }

  .m-q-m-a {
    margin: auto !important;
  }
}

.modal-backdrop,
.modal {
  z-index: 555 !important;
}

.j-c-s-b {
  justify-content: space-between !important;
}

.des {
  padding: 15px;
}

.w-40 {
  width: 40rem !important;
}

.h-40 {
  height: 40rem !important;
}

.ii {
  float: right;
}

button.sell-button {
  float: right;
  /* margin-top: 7rem; */
  margin-right: 0.5rem;
  background-color: transparent;
  padding: 0.5rem 2rem;
  border-radius: 10px;
}

img.card-img-top {
  height: 12rem !important;
}

img.card-img-top.h-18 {
  height: 18rem !important;
}

img.card-img-top {
  max-width: 100% !important;
  width: 100% !important;
}

* {
  border-color: var(--borders_clr) !important;
}

.sell-button {
  float: right;
  margin-top: 2rem;

  border: 1px solid !important;
  /* margin-top: 7rem !important; */
  margin-bottom: 1rem !important;
  /* margin-right: 1rem !important; */
}

.sell-buttons {
  float: right;
  /* margin-top: 2rem; */
  border: 1px solid !important;
  /* margin-top: 7rem !important; */
  margin-bottom: 1rem !important;
  /* margin-right: 1rem !important; */
}

@media screen and (max-width:1200px) {
  .sell-buttons {
    /* float: right; */
    /* margin-top: 2rem; */
    border: 1px solid !important;
    /* margin-top: 7rem !important; */
    margin: 1rem 0rem !important;
    /* margin-right: 1rem !important; */
  }
}

.f-s-0_8 {
  font-size: 0.8rem !important;
}

.b-r-10 {
  border-radius: 10px !important;
}

img#card-nft-main-image {
  border-radius: 0px 0px 10px 10px !important;
}

#hr2 {
  color: var(--borders_clr) !important;
  opacity: 1 !important;
  margin: 0 !important;
}

.h-3 {
  height: 3rem !important;
}

.modal-content {
  max-width: 60rem !important;
}

.b-c-t {
  background-color: transparent !important;
}

.b-1 {
  border: 1px solid var(--borders_clr) !important;
}

/* .b-r-1 {
  border-right: 1px solid var(--para-clr) !important;
} */

.input-field {
  max-width: 30rem !important;
  margin: auto !important;
}

.input-field-sell {
  border-radius: 25px;
  margin-bottom: 1rem;
}

.m-b-2 {
  margin-bottom: 2rem !important;
}

/* body{
    word-break: break-all !important;
} */
#input-submit-btn {
  border-right: 1px solid #e5e8eb !important;
}

#input-submit-btn:focus {
  border: none !important;
  outline: none !important;
  border-right: 1px solid var(--para-clr) !important;
}

.para_clr {
  color: var(--para-clr) !important;
}

.p-y-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.sell-btn {
  background-color: transparent;
  padding: 0.5rem 2rem;
  border-radius: 10px;
}

.m-b-1 {
  margin-bottom: 1rem !important;
}

.merge {
  color: #fec200 !important;
}

.share {
  margin-left: 25px;
}

.merge25 {
  margin-top: 40px;
  font-size: 1.3rem !important;
  /* color: rgb(53, 56, 64) !important; */
}

.view {
  margin-top: 20px !important;
}

.ss {
  margin-right: 20px;
}

.eye {
  margin-right: 10px;
  font-size: 1.5rem;
}

.num {
  font-size: 1.3rem;
}

.w-s-n-w {
  white-space: nowrap !important;
}

.b-n {
  border: none !important;
}

.j-c-s-b {
  justify-content: space-between !important;
}

.p-l-0 {
  padding-left: 0rem !important;
}

.p-l-0_5 {
  padding-left: 0.5rem !important;
}

.num-sub {
  font-size: 15px;

  margin-left: 10px;
  font-weight: 600;
}

.best-con {
  /* border: 1px solid; */
  border-radius: 15px;
  /* padding: 20px 24px; */
  margin-top: 25px;
}

.bu {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 12px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  padding: 15px 100px;
  background-color: transparent;
  border: 2px solid var(--borders_clr) !important;
  color: #fec200;
  width: 100% !important;
}

.tag {
  font-size: 1.2rem;
  margin-right: 10px;
  margin-top: 5px;
}

.off {
  color: rgb(112, 122, 131);
}

.price-gr {
  margin-right: 15px;
  font-size: 1.5rem;
}

.listing {
  text-align: center;
}

.list-no {
  text-align: center;
  margin-top: 10px;
}

.accordion-button:not(.collapsed) {
  background: transparent !important;
  color: inherit !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
  color: var(--para-clr) !important;
}

.numbers-bar {
  float: right;
}

.value {
  color: rgb(112, 122, 131);

  font-size: 1rem;
  font-weight: 600;
}

.value-item {
  margin-bottom: 10px;
}

.properties-box {
  background-color: rgba(21, 178, 229, 0.06);
  border-radius: 6px;
  border: 1px solid rgb(21, 178, 229);
  padding: 10px;
  text-align: center;
  /* width: 30%; */
}

.row.m-t-7.m-q-a-d-n {
  width: 100% !important;
}

button {
  border-color: #e5e8eb !important;
}

.properties-na {
  color: rgb(21, 178, 229);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}

.w-100 {
  width: 100% !important;
}

.m-t-2 {
  margin-top: 2rem !important;
}

.m-t-5 {
  margin-top: 5rem !important;
}

.m-t-7 {
  margin-top: 7rem !important;
}

@media screen and (max-width: 1200px) {
  .m-q-b-d-n {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .m-q-a-d-n {
    display: none !important;
  }
}

.merg {
  font-size: 1rem;
  font-weight: 600;
  /* line-height: 30px; */
}

.p-x-0_25 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.m-y-0_25 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.merg-kk {
  font-size: 13px;
  line-height: 16px;
  min-height: 16px;
}

.contract {
  float: right;
}

.tab {
  font-size: 15px;

  font-family: "Poppins";
  font-weight: 700;
  margin-top: 8px;
}

.merge {
  width: 15%;
  border-radius: 10px;
}

.merge-details {
  margin-left: 15px;
}

.m-d-p {
  font-size: 14px;
  font-weight: 600;
}

.twitter {
  border: 2px solid;
  background-color: transparent !important;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.eth {
  border: 2px solid;
  background-color: transparent !important;
  padding: 8px;
  border-left: none;
  border-right: none;
  font-size: 2rem;
  margin-top: 0px;
}

.dot {
  border: 2px solid;
  background-color: transparent !important;
  padding: 17px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.social-icon {
  margin-top: 25px;
}

.collection-btn {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 12px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  padding: 17px 24px;
  background-color: transparent !important;
  border: 2px solid;
  color: #fec200;
}

.view-btn1 {
  text-align: center;
}

.input-filter-me {
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 12px;
  border: 2px solid rgb(229, 232, 235);
  color: rgb(4, 17, 29);
  width: 100%;
  padding: 12px;
  height: 48px;
  font-size: 16px;
}

.input-group {
  width: 100% !important;
}

.dropdown-menu.show.dropdown-menu-end {
  width: 100%;
}

.accordion-body {
  border-bottom: 1px solid rgb(229, 232, 235);
}

.span-pp {
  font-size: 15px;

  font-family: "Poppins";
  font-weight: 700;
}

.price-amu {
  font-size: 15px;

  font-family: "Poppins";
  font-weight: 900;
}

.from-te {
  color: #fec200;
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 900;
}

.trans {
  margin-right: 8px;
  font-size: 1.3rem;
}

.from-da {
  color: #fec200;
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 900;
}

.sol-a {
  font-size: 11px;
  margin-left: 8px;
  color: rgb(52, 199, 123);
}

.accordion-collapse.collapse.show,
.accordion {
  background-color: transparent !important;
  --bs-accordion-bg: transparent !important;
  --bs-accordion-color: inherit !important;
}

/* svg{
    fill: var(--para-color) !important;
    color: var(--para-color) !important;
} */

.grshare {
  color: var(--para-color) !important;
  fill: var(--para-color) !important;
}

.container-kws {
  width: 95% !important;
  margin: auto !important;
}

.card-body-mergevv {
  background-color: transparent !important;
}

/* @media screen and (max-width: 1200px) {
    .container-kws {
        width: 95% !important;
        margin: auto !important;
    }
} */

.accordion-button {
  color: inherit !important;
}

#button-sell-color {
  color: var(--para-color) !important;
  /* border-color: #e5e8eb !important; */
  border: none !important;
  background-color: var(--yellow_clr) !important;
  color: var(--opp) !important;
  width: 12rem !important;
  /* box-shadow: 0px 0px 20px 1px #B14BED !important; */
}



/* .linear_gradient {
  // fill: linear-gradient(327deg, #BD11FA -40.61%, #46C2FF 108.85%) !important;
  background: linear-gradient(to left, #BD11FA, #46C2FF);
  box-shadow: 0px 0px 20px 1px #BD11FA !important;
  color: var(--color) !important;
}

.linear_gradient:hover {
  // fill: linear-gradient(327deg, #BD11FA -40.61%, #46C2FF 108.85%) !important;
  background: linear-gradient(to right, #BD11FA, #46C2FF);
  // box-shadow: 0px 0px 20px 1px #BD11FA !important;
  box-shadow: none !important;
  color: var(--color) !important;
} */


/* *{
    word-break: break-all !important;
} */

#card-nft-main-image {
  object-fit: cover;
  width: auto !important;
  height: auto !important;
  max-width: 100% !important;
  max-height: 100% !important;
  border-radius: initial !important;
}

.m-y-0_5 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-y-0_75 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.primary-btn {
  border: 1px solid var(--para-clr);
  padding: 0.5rem 3rem !important;
  border-radius: 10px !important;
  background-color: transparent !important;
  color: var(--para-clr) !important;
  box-shadow: 0px 0px 5px var(--para-clr) !important;
}

.iPiVBj {
  position: relative;
  height: 0px;
  padding-bottom: 100%;
}

.inwolf {
  inset: 0px;
  position: absolute;
}

.ikUvYq {
  height: 100%;
  width: 100%;
}

.eKcYOL .item--media-frame .item--media {
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0px auto;
}

.hObjOE {
  min-height: inherit;
  border-radius: inherit;
  height: 100%;
  width: 100%;
  position: relative;
}

.bhxycR {
  -webkit-box-align: center;
  align-items: center;
}

.jZNcco {
  -webkit-box-pack: center;
  justify-content: center;
}

.dsowZP {
  flex-direction: column;
}

.bNkKFC {
  display: flex;
}

.jLlAQq {
  height: 100%;
  min-height: inherit;
  width: 100%;
  position: relative;
  border-radius: inherit;
}

.hObjOE .AssetMedia--animation {
  position: relative;
  min-height: inherit;
  max-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: inherit;
}

.hObjOE .AssetMedia--animation .AssetMedia--playback-wrapper {
  height: 100%;
  max-width: 100%;
  width: 100%;
  position: relative;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: inherit;
}

.bhxycR {
  -webkit-box-align: center;
  align-items: center;
}

.dsowZP {
  flex-direction: column;
}

.bNkKFC {
  display: flex;
}

.hObjOE .AssetMedia--animation .AssetMedia--playback-wrapper .AssetMedia--video {
  height: 100%;
  width: 100%;
}

@media (min-width: 1024px) {
  .eKcYOL .item--media-frame {
    margin-left: 0px;
    margin-right: 20px;
  }
}

@media (min-width: 1024px) {
  .eKcYOL .item--frame {
    margin: 20px;
  }
}

.eKcYOL .item--media-frame {
  margin: 20px 0px;
  position: relative;
}

.eKcYOL .item--frame {
  margin: 4px 0px;
}

.bJqyHU {
  border-radius: 10px;
  border: 1px solid rgba(18, 18, 18, 0.12);
  overflow: hidden;
}