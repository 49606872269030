.background-collections {
  height: 25rem;
  background: linear-gradient(255.46deg,
      #ffbc39 18.86%,
      #de5d34 40.83%,
      #f74242 82.55%);
}

h1.collection-head-stats {
  position: absolute;
  bottom: 5%;
  left: 0%;
  transform: translate(10%, -50%);
  color: white;
}

.collection-head {
  padding-top: 18rem;
  margin-left: 8rem;
}

.collections-state {
  margin-bottom: 5rem;
}

.toggle-button {
  height: 48px;
  background: var(--profile-notification-switch) !important;
  border-radius: 15px;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 126%;
  color: var(--para-clr) !important;
  border: none !important;
}

.timer-content {
  justify-content: space-evenly;
  height: 48px;
  background: var(--profile-notification-switch);
  border-radius: 15px;
  align-items: center;
  cursor: pointer;
}

.hour-span {
  border-radius: 8px;
  cursor: pointer;
  font-family: "Futura Maxi CG Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
}


.reload-button {
  width: 100%;
  height: 48px !important;
  background: var(--profile-notification-switch) !important;
  border-radius: 15px !important;
  cursor: pointer;
}

.timer-1 {
  font-family: "Futura Maxi CG Bold" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 15px;
  align-items: center;
}

.button-explore {
  height: 56px !important;
  background: #fec200 !important;
  border-radius: 15px !important;
  border: none !important;
  font-family: "Century Gothic" !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  align-items: center !important;
  color: #101010 !important;
  text-decoration: none !important;
  padding: 26px 50px !important;
}

.content-section {
  text-align: center;
  height: 30rem;
}

.nothing-explore {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.css-quj9j6-MuiTable-root {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 1200px !important;
}


.collection-watchlist-text {
  margin-bottom: 2rem !important;
  font-size: 1.5rem !important;
}

.categories-container.dropdown {
  margin-left: 0.5rem;
}

@media screen and (max-width: 1200px) {
  .categories-container {
    margin-left: 14px;
  }
}

@media screen and (max-width: 1197px) {
  .timer-1 {
    padding-left: 10px;
  }
}

@media screen and (max-width: 899px) {
  .categories-container {
    margin-left: 0px;
  }

  .reload-button {
    margin: 10px 0px !important;
  }
}

@media screen and (max-width: 599px) {
  .toggle-button {
    margin-top: 10px;
  }
}

@media screen and (max-width: 887px) {
  button#dropdown-basic {
    margin-left: 15px;
  }

  .reload-button {
    margin-top: 0px !important;
    width: 50%;
  }

  .grid-btn {
    text-align: center !important;
  }

  .timer-content {
    width: 96% !important;
  }


  .timer-1 {
    margin-left: 15px;
  }
}

@media screen and (max-width: 899px) {
  .reload-button {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 599px) {
  .reload-button {
    margin-top: 10px !important;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiTableContainer-root.css-13xy2my {
  padding: 0rem 2.2rem;
}

.css-18qulp9 {
  min-width: 1200px !important;
}

.MuiGrid-root.MuiGrid-container.px-3.css-1d3bbye {
  padding: 0rem 2.2rem !important;
}
.css-13xy2my{
  background-color: var(--bg-clr);
}
.css-1jaw3da{
  margin-right: 0px !important;
}