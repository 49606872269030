.background-images-collections {
    /* background-image: url(./Collection-images/Rectangle719.png); */
    width: 100% !important;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 485px; */
}

.profile-setting-img {
    margin-top: -170px;
}

.g-1 {
    gap: 1rem !important;
}

.profile-name-inside {
    margin-top: 10px;
    /* font-family: 'Futura Maxi CG Bold'; */
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 26px;
}

#h_18_5 {
    height: 18.5rem !important;
}

* {
    scrollbar-width: thin;
    /* or any width you prefer */
}

/* For Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
    /* or any width you prefer */
}

/* Change scrollbar color */
/* For Firefox */
* {
    scrollbar-color: red green;
    /* Change these colors to your preference */
}

/* For Chrome, Edge, and Safari */
*::-webkit-scrollbar-thumb {
    background-image: linear-gradient(#C734F6, #2AD5B4);
    /* Change this color to your preference */
    border-radius: 10px !important;
}

.bg_clr_blue {
    background-color: #2C6BAD;
}

.bg_clr_blue:hover {
    background-color: #4398f3;
}

.m-t-_5 {
    margin-top: -5rem !important;
}

.t-a-r_mq {
    text-align: right !important;
}


video#border_rad {
    height: 18rem !important;
}

img.card-img-top.h-18_56.b-r-t {
    height: 21.5rem !important;
}

.m-t-_3 {
    margin-top: -3rem !important;
}

.m-t-_7 {
    margin-top: -7rem !important;
}

img.card-img-top.h-100.b-r-t {
    height: 100% !important;
}


.b-clr_w {
    border-color: white !important;
}

.h-30vh {
    height: 40vh !important;
}

@media screen and (max-width:1200px) {
    .h-30vh {
        height: 20vh !important;
    }

    .t-a-r_mq {
        text-align: left !important;
    }

    .m-t-_7 {
        margin-top: -5rem !important;
    }



}

.b-r-t {
    border-radius: 5px 5px 0px 0px !important;
}

#border_rad {
    border-radius: 5px 5px 0px 0px !important;

}

video {
    width: 100% !important;
}

.icons-collections-inside {
    float: right;
    margin-top: 30px;
}

.icons-setting-width {
    margin-left: 15px;
    fill: var(--para-clr);
}

.icons-setting-width11 {
    margin-left: 15px;
    margin-right: 30px;
}

.collection-inside-bio {
    /* font-family: 'Futura Maxi CG Book'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    margin-top: 20px;
}

@media screen and (max-width:1200px) {
    #collection-inside-margin {
        margin: 0px !important;
    }
}

.collection-container {
    width: 95% !important;
    margin: auto;
    margin-bottom: 5rem;
}

.see-button {
    border-radius: 13.8265px;
    border: none;
    padding: 0.5rem 1rem;
    color: var(--para-clr);
    border: 1px solid var(--para-clr);
    background-color: var(--bg-clr);
}

.content-collection-data {
    margin-top: 40px !important;
}

.eth-section {
    /* font-family: 'Futura Maxi CG Bold'; */
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    align-items: center;
}

.co-volume-section {
    /* font-family: 'Futura Maxi CG Book'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    align-items: center;
}

.reload-bu {
    width: 46.74px;
    height: 48px;
    background: rgba(246, 246, 246, 0.5);
    border-radius: 15px;
    margin-left: 1rem;
    text-align: center;
}

.reolad-tfi-bu {
    margin-top: 15px;

    font-size: 20px;
    font-weight: 900;
}

.filterlist-icons {
    margin-top: 10px;
}

.view-bu-collection {
    width: 91.72px;
    height: 48px;
    background: rgba(246, 246, 246, 0.5) !important;
    border-radius: 15px;
    padding: 15px;
}

.vv-bu1 {
    background: #fff;
    border-radius: 5px;
    margin-right: 15px;
    padding: 5px;
}

.accordion-collect {
    border-bottom: none !important;
}

.bu-toggle {
    display: flex;
    justify-content: space-between;
}

.check-box-bu {
    margin-top: -7px;
}

.status-tag {
    /* font-family: 'Futura Maxi CG Book' !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    margin-bottom: 25px;
}

.bu-nw-tag {
    /* font-family: 'Futura Maxi CG Book'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

.end-time-card {
    /* font-family: 'Futura Maxi CG Book'; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
}

#form-search-input-collection {
    border: none !important;
    border-radius: 15px !important;
    /* background-color: var(--background-collection-filter) !important; */
    background-color: transparent !important;
    height: 48px !important;
}

.css-1vm0r4s-MuiPaper-root {
    box-shadow: none !important;
}

/* .slick-prev:before,
.slick-next:before {
    background-color: black !important;
    color: white !important;
    border-radius: 50% !important;
    font-size:35px !important;
} */
.search_global {
    border: none !important;
}

.filter-icon-collection {
    margin-left: 0px !important;
}

.collection-filter {
    background-color: var(--background-collection-filter) !important;
}

.status-tag {
    font-weight: 800 !important;
    font-size: 20px !important;
    margin-top: 16px !important;
}

.cards-title-name {
    /* font-family: 'Futura Maxi CG Book' !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 15px !important;
    color: #353535 !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    background-color: transparent !important;
}

.css-vubbuv {
    fill: var(--para-clr) !important;
    color: var(--para-clr) !important;
}

@media screen and (max-width:768px) {
    .icons-collections-inside {
        float: left;
        margin: 2rem 0rem;
    }
}

svg#search-icon-nav {
    fill: white !important;
}

.m-t-3 {
    margin-top: 3rem !important;
}

.m-t-2 {
    margin-top: 2rem !important;
}

.kmazGs {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    /* background-color: rgb(255, 255, 255); */
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 15px;
    transition: box-shadow 0.25s ease-in-out 0s;
}

a.dropdown-item,
.dropdown-menu {
    background-color: var(--bg-clr) !important;
    color: var(--para-clr) !important;
}

.b-5px {
    border: 5px solid white !important;
}

.clrs {
    color: var(--para-clr) !important;
}

.b-c-t {
    background-color: transparent !important;
}

.kmazGs .Asset--anchor {
    pointer-events: initial;
}


.kmazGs .Asset--anchor {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    border-radius: inherit;
}

.b-c-w {
    border-color: white !important;
}

.eiItIQ:hover {
    color: rgb(24, 104, 183);
}

.eiItIQ {
    color: rgb(32, 129, 226);
    text-decoration: none;
}

.iwDNLZ {
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-bottom: 100%;
}

.cQBoPP {
    position: relative;
}

.inwolf {
    inset: 0px;
    position: absolute;
}

.hObjOE {
    min-height: inherit;
    border-radius: inherit;
    height: 100%;
    width: 100%;
    position: relative;
}

.bhxycR {
    -webkit-box-align: center;
    align-items: center;
}

.jZNcco {
    -webkit-box-pack: center;
    justify-content: center;
}

.dsowZP {
    flex-direction: column;
}

.bNkKFC {
    display: flex;
}

.jLlAQq {
    height: 100%;
    min-height: inherit;
    width: 100%;
    position: relative;
    border-radius: inherit;
}

.hObjOE .AssetMedia--animation {
    position: relative;
    min-height: inherit;
    max-height: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: inherit;
}

.hObjOE .AssetMedia--animation .AssetMedia--playback-wrapper {
    height: 100%;
    max-width: 100%;
    width: 100%;
    position: relative;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: inherit;
}

.bhxycR {
    -webkit-box-align: center;
    align-items: center;
}

.jZNcco {
    -webkit-box-pack: center;
    justify-content: center;
}

.dsowZP {
    flex-direction: column;
}

.bNkKFC {
    display: flex;
}

.hObjOE .AssetMedia--animation .AssetMedia--playback-wrapper .AssetMedia--video {
    height: 100%;
    width: 100%;
}

.cyJtmY.cyJtmY:hover {
    opacity: 0.8;
}

.cyJtmY.cyJtmY {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 44px;
    -webkit-box-pack: center;
    justify-content: center;
    bottom: 8px;
    right: 8px;
    width: 44px;
    z-index: 1;
    position: absolute;
    color: rgb(255, 255, 255);
    background-color: rgba(159, 159, 159, 0.4);
    backdrop-filter: blur(10px);
}

.dotwvS {
    text-rendering: optimizelegibility;
    cursor: pointer;
    font-feature-settings: "liga" !important;
    font-size: 24px !important;
}

.material-icons-outlined {
    /* font-family: 'Material Icons Outlined'; */
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
}

.fYtAai {

    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    gap: 1rem !important
}

@media (min-width: 20rem) {
    .fYtAai {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
}

@media (min-width: 30rem) {
    .fYtAai {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
}

@media (min-width: 40rem) {
    .fYtAai {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
}

@media (min-width: 50rem) {
    .fYtAai {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    }
}

@media (min-width: 60rem) {
    .fYtAai {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    }
}

@media (min-width: 70rem) {
    .fYtAai {
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
    }
}

@media (min-width: 80rem) {
    .fYtAai {
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
    }
}

@media (min-width: 100rem) {
    .fYtAai {
        grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
    }
}

.h-21 {
    height: 21rem !important;
}

.link-input7 {
    width: 100%;
    padding: 10px;
    border-top: 1px solid #D9D9D9;
    border-bottom: none;
}

.p-t-10px {
    padding: 10px !important;
}