.tab-select {
  margin-top: 30px;
}

.tab1 {
  /* font-family: "Futura Maxi CG Bold" !important; */
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 17px !important;
  align-items: center !important;
  color: var(--para-clr) !important;
}

.tab1:hover {
  color: rgb(120, 119, 119) !important;
}

.content-text {
  /* font-family: 'Futura Maxi CG Book'; */
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  align-items: center;
}
#h-26{
  height: 21rem !important;
}

.setting-page {
  height: 100vh;
  align-items: center;
  text-align: center;
}

.dropdown-menu {
  padding: 0 !important;
}

.profile-recently {
  background: var(--profile-notification-switch) !important;
  color: var(--secondary-para-clr);
}