.button-section990 {
  background-color: var(--profile-notification-switch);
  padding: 1rem;
  border-radius: 10px;
}

.earning-content {
  /* font-family: "ITC Avant Garde Gothic Std" !important; */
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 120% !important;
  letter-spacing: 0.005em !important;
}

.earnigs-head {
  /* font-family: "Futura Maxi CG Bold"; */
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
}

.profile-setting-heading{
  font-size: 2rem !important;
  /* font-family: "Futura Maxi CG Bold" !important; */
  margin-bottom: 2rem !important;
}
.earning-box {
  margin-top: 40px;
  margin-left: 30px;
  width: 80%;
}

.earning-content1102 {
  /* font-family: "Poppins" !important; */
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;

  text-align: center !important;

}

@media screen and (max-width:1200px) {
  .earning-box {
    width: 100%;
  }
}

@media screen and (max-width:767px) {
  .earning-box {
    width: 95%;
  }
}

@media screen and (max-width:980px) {
  .earning-box {
    margin: 20px;
  }
}