.progress-bar-create {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden !important;
  }
  .progress-bar-event {
    width: 50%;
    background-color: rgba(0, 0, 0);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    border: 1px solid #fec200;
    border-radius: 10px;
    padding: 2rem;
    color: #fff;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    .progress-bar-event {
      width: 95%;
    }
  }
  .progress-bar-text {
    color: black !important;
    display: block;
    position: relative;
  }
  #create-event-page-progress .progress-bar {
    background-color: #fec200 !important;
  }
  .circle1 {
    border: 3px solid #fec200;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: block;
    position: absolute;
    top: -8px;
    left: 0%;
    background-color: #fec200;
  }
  .circle2 {
    border: 3px solid #fec200;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: block;
    position: absolute;
    top: -8px;
    left: 50%;
    background-color: #fec200;
  }

  .circle3 {
    border: 3px solid #fec200;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: block;
    position: absolute;
    top: -8px;
    right:-5px;
    background-color: #fec200;
  }
  
  
  