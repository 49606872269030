.account-head {
  font-family: "Futura Maxi CG Bold" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 30px !important;
  line-height: 35px !important;
}

.account-sec {
  font-family: "ITC Avant Garde Gothic Std" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 120% !important;
  letter-spacing: 0.005em !important;

}

.accordion-1 {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 126% !important;
}

.para-typography {
  font-family: "ITC Avant Garde Gothic Std" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 120% !important;
  letter-spacing: 0.005em !important;
  color: var(--para-clr) !important;
}

.accordion-width {
  width: 100%;
  margin-left: 0px;
}

#panel1a-header {
  border-bottom: 0.5px solid #6a6a6a;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: none !important;
  color: var(--para-clr) !important;
}

.css-i4bv87-MuiSvgIcon-root {
  fill: var(--para-clr) !important;
}

#panel2a-header {
  border-bottom: 0.5px solid #6a6a6a;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.select-section {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 126%;
  color: var(--para-clr);
}

.lock-button {
  width: 25%;
  height: 53px;
  border: 1px solid #dd0000;
  border-radius: 10px;
  margin: 2rem 0rem;
  background-color: transparent !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 126% !important;
  color: #dd0000 !important;
}

.accordion-next {
  width: 100%;
}

.para-typography11 {
  font-family: "ITC Avant Garde Gothic Std" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 120% !important;
  letter-spacing: 0.005em !important;
  color: var(--para-clr) !important;
  width: 50%;
}

.nothing-account {
  width: 100%;
  height: 53px;
  background: var(--profile-notification-switch);
  border-radius: 10px;
  margin-top: 20px;
  border: none;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 126% !important;
  color: var(--para-clr) !important;
}

.section-fit {
  margin-top: 100px;
  margin-left: 50px;
}

.issue-account {
  width: 100% !important;
  border-radius: 10px;
  border: 1px solid var(--para-clr) !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--para-clr) !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: transparent !important;
}

/* MEDIA QUERY */
@media screen and (max-width:1087px) {
  .lock-button {
    width: 30%;
  }

  .section-fit {
    margin-top: 50px;
    margin-left: 35px;
  }
}

@media screen and (max-width:811px) {
  .lock-button {
    width: 35%;
  }
}

@media screen and (max-width:766px) {
  .section-fit {
    margin-top: 50px;
    margin-left: 15px;
  }
}

@media screen and (max-width:408px) {
  .lock-button {
    width: 40%;
  }

  .section-fit {
    margin-top: 40px;
    margin-left: 15px;
  }
}

@media screen and (max-width:330px) {
  .lock-button {
    width: 50%;
  }
}