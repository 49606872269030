@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

img.safu-img {
  width: 250px;
  margin: 50px 30px 20px;
}

img.game-img {
  width: 250px;
  display: block;
  margin-right: auto;
}

.p-t-9 {
  padding-top: 9rem !important;
}

.slider-container {
  margin-top: 112px;
  width: 100%;
  border-radius: 20px;
}

.br-12 {
  border-radius: 12px !important;
}

.loader_slider {
  width: 10rem;
  height: 10rem;
}

button.enter-button {
  width: 170px;
  height: 60px;
  font-weight: 700;
  margin-left: 30px;
  display: block;
  margin-bottom: 20px;
  border-radius: 20px;
  border: none;
}

img.slider1 {
  padding: 0rem !important;
  width: 100%;
  /* height: 22rem; */
  border-radius: 20px;
}

.m-q-h-25 {
  height: 27rem !important;
}

@media screen and (max-width:1800px) {
  .m-q-h-25 {
    height: 23rem !important;
  }
}


@media screen and (max-width:1600px) {
  .m-q-h-25 {
    height: 21rem !important;
  }
}

@media screen and (max-width:1500px) {
  .m-q-h-25 {
    height: 20rem !important;
  }
}

@media screen and (max-width:1400px) {
  .m-q-h-25 {
    height: 18rem !important;
  }
}

/* @media screen and (max-width:1000px) {
  .m-q-h-25 {
    height: 17rem !important;
  }
} */

.bg_clr_blacks {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.788));
}

.b-r-btm {
  border-radius: 0px 0px 20px 20px !important;
}

.b_0 {
  bottom: 0rem !important;
}

.l_0 {
  left: 0rem !important;
}

.b_c_w {
  background-color: var(--header_bg) !important;
  padding: 1rem !important;
}

img.slider2 {
  padding: 0.5rem !important;
  width: 100%;
  height: 15rem;
  border-radius: 20px;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 20px;
  padding: 0;
  cursor: pointer !important;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 5px;
  border-radius: 25px;
  background-color: var(--para-clr);
  content: "";
  text-align: center;
  margin-top: 20px;
}

.slick-prev:before,
.slick-next:before {
  opacity: 0 !important;
  /* box-shadow: 0px 0px 10px black; */
  /* border-radius: 50%; */
}

.slick-prev:before,
.slick-next:before {
  color: white !important;

  font-size: 2.5rem;
}

.slick-prev,
.slick-next {
  z-index: 1 !important;
}

@media screen and(max-width:912px) {
  .slider-container {
    width: 100%;
  }

  img.safu-img {
    width: 250px;
    margin: 50px 30px 20px;
  }

  img.game-img {
    width: 250px;
    display: block;
    margin: auto;
  }
}

@media screen and(max-width:480px) {
  .slider-container {
    width: 100%;
  }
}

.slick-active button {
  color: #000000 !important;
}

.slick-dots li.slick-active button:before {
  color: #48ff7b !important;
  background-color: var(--para-clr) !important;
}

#enter-12 {
  color: black !important;
}

.slick-prev {
  left: -3px !important;
}

.slick-next {
  right: 15px;
}

@media screen and (max-width: 768px) {
  .slick-prev {
    left: -3px !important;
  }

  .slick-next {
    right: 5px;
  }
}

img.card-img-top {
  border-radius: 15px 15px 0px 0px !important;
}

.text32 {
  font-size: 0.8rem !important;
}

/* Custom arrow styles */
.custom-arrow-prev,
.custom-arrow-next {
  background-color: white !important; /* White background */
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
}

.custom-arrow-icon {
  color: black !important; /* Black arrow icon color */
  font-size: 24px !important;
}

/* Adjust the position of arrows as needed */
.custom-arrow-prev {
  left: -10px !important;
}

.custom-arrow-next {
  right: -10px !important;
}
