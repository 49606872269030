.background-image {
    background-image: url("../Images/Rectangle 720.png");
    width: 1440px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 485px;
}

.explore-header {
    padding-top: 130px !important;
    padding-left: 30px;
    color: #fff;
}
img{
    max-width: 100% !important;
}

.css-1u80wl{
    box-shadow: none !important;
}