.cDfrIB {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    flex-direction: column;
    /* margin-top: -72px; */
    background-size: cover;
    position: relative;
    aspect-ratio: 12 / 5;
    padding: 0px;
    width: 100vw;
    min-height: calc(125vw);
}

.swiper-button-prev:after, .swiper-button-next:after{
    font-size: 1rem !important;
}

@media screen and (min-width: 1024px) {
    .cDfrIB {
        min-height: calc(41.6vw);
    }

    .iUZLxa {
        height: 88px;
        width: 88px;
        border-radius: 12px;
    }

    .hkHXpY {
        top: 4px;
    }
}

.image_bgg {
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 0px;
    position: absolute;
    inset: 0px;
}

.image_bgg_inside {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center center;
}

.ixSLhZ {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
    /* z-index: 2; */
}

.buAnIP {
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.bNkKFC {
    display: flex;
}

@media screen and (min-width: 1200px) {
    .dxaCRO span {
        position: relative;
        top: -2px;
    }

    .itfOsM {
        margin-top: 12px;
    }


    /* .gkoOuE {
        padding: 48px 32px;
    } */

    .doJkTB {
        margin-top: 32px;
    }



    .ibjlmS {
        font-size: 48px;
        line-height: 56px;
    }

    .hkHXpY {
        height: 34px;
        width: 33px;
    }
}

.PpLlC {
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.dsowZP {
    flex-direction: column;
}

.bNkKFC {
    display: flex;
}

@media screen and (min-width: 0px) {
    .iUZLxa {
        height: 64px;
        width: 64px;
        border-radius: 10px;
    }

    .ibjlmS {
        font-size: 24px;
        line-height: 32px;
    }
}

.iUZLxa {
    position: relative;
    width: 100%;
    height: 100%;
    border: 3px solid rgb(255, 255, 255);
    background-color: black;
    overflow: hidden;
}

.img_preview {
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 0px;
    position: absolute;
    inset: 0px;
}

.small_img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: left center;
}

.dsowZP {
    flex-direction: column;
}

.bNkKFC {
    display: flex;
}

.doJkTB {
    margin-top: 24px;
    align-items: flex-start;
}

.gComsc {
    margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 110%;
}

.dxaCRO {
    color: white;
    margin: 0px;
}

@media screen and (min-width:768px) {
    .ibjlmS {
        font-size: 32px;
        line-height: 36px;
    }

    .hkHXpY {
        height: 25px;
        width: 24px;
        position: relative;
        top: 2px;
    }

    .itfOsM {
        margin-top: 12px;
    }

}

.dxaCRO span {
    display: inline-block;
    margin-left: 8px;
}

.hkHXpY {
    height: 17px;
    width: 16px;
}

.ciZrIX {
    height: 1rem;
    width: 1rem;
}

.itfOsM {
    margin-top: 8px;
    -webkit-box-align: center;
    align-items: center;
}

.kBCuOk.kBCuOk {
    margin: 0px;
    color: white;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.clBPfX {
    color: rgb(18, 18, 18);
    font-family: __Inter_94fae5, __Inter_Fallback_94fae5, sans-serif;
    font-variant-ligatures: no-contextual;
    --text-medium: 16px;
    --text-small: 14px;
    --text-tiny: 12px;
    --text-lh-medium: 24px;
    --text-lh-small: 20px;
    --text-lh-tiny: 18px;
    font-weight: 600;
    --text-weight: 600;
    font-size: 16px;
    line-height: 24px;
}


.frrCDQ {
    margin: 0px;
}

.fMWhSV {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    color: rgb(18, 18, 18);
    font-weight: inherit;
}

.RYcZ {
    height: 24px;
    font-weight: inherit;
}

.kBCuOk.kBCuOk span {
    font-weight: 600;
}

.kBCuOk.kBCuOk a {
    color: white;
}

.fMWhSV .AccountLink--ellipsis-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.bSLFdB {
    color: rgb(32, 129, 226);
    text-decoration: none;
    font-weight: inherit;
}

.crebQt {
    font-weight: inherit;
}

.axQXd {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.blue_hover {
    background: #2c6bad !important;
}

.blue_hover:hover {
    background: #3d8add !important;
}

.gray_hover {
    background-color: var(--border_clrs) !important;
}

.gray_hover:hover {
    background-color: var(--border_hover_clrs) !important;
}

.box_shadow {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
}

.bg_clr_time {
    background-color: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(20px);
}