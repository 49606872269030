.tab-button {
    display: flex;
    margin-left: auto;
}

#button-22 {
    width: 85px;
    height: 40px;
    border: 1px solid;
    border-radius: 8px;
    font-family: 'Futura Maxi CG Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    align-items: center;
    color: var(--border-clr);
    background: var(--bg-clr);
}

.view-bu {
    margin-left: 10px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    opacity: 0.5;
    text-transform: capitalize !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    opacity: 1 !important;
}