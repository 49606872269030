.row.side-bar {
  display: grid;
  border-right: 1px solid rgb(80, 79, 79);
}

.side-items {
  font-family: "Futura Maxi CG Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #6a6a6a;
}

.side-bar {
  width: 272px;
  height: 56px;
  display: flex;
  background: rgb(254, 194, 0, 0.15);
  align-items: center;
}

.b-1_dotted {
  border: 3px dashed;
}

.side-bar1 {
  width: 272px;
  height: 56px;
  display: flex;
  align-items: center;
}

ul {
  list-style: none;
}

.profile-setting {
  background-color: var(--profile-setting-tab-clr);
}

.section-list {
  margin-top: 10px;
}

.content-style {
  font-family: "Futura Maxi CG Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: var(--para-clr);
}

.icons-tabs {
  color: var(--para-clr);
  margin-right: 10px;
}

.iconsddd {
  margin-right: 10px;
  color: var(--para-clr) !important;
}

.listed-section {
  margin-top: 50px;
}

.setting-sections {
  font-family: "Futura Maxi CG Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 120%;
  align-items: center;
  letter-spacing: 0.005em;
  color: var(--para-clr);
}

.section-color {
  box-shadow: 3px 0px 10px var(--box-shadow-clr);
}

#twitter-profile-icon {
  fill: #fec200 !important;
}

/* PROFILE SECTION */
.profile-heading {
  /* font-family: "Futura Maxi CG Bold" !important; */
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 30px !important;
  line-height: 35px !important;
  color: var(--para-clr) !important;
}

.preview-profile-button {
  width: 120px;
  height: 43px;
  background: var(--bg-clr);
  border-radius: 15px;
  padding: 10px;
  margin-top: 20px;
  /* font-family: "Futura Maxi CG Book"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  color: var(--para-clr);
  border: 1px solid var(--para-clr);
  margin-left: 3rem;
}

.profile-section-banner {
  /* font-family: "Futura Maxi CG Bold" !important; */
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 19px !important;
  color: var(--para-clr) !important;
  margin-top: 40px;
}

.section-profile-line {
  width: 100%;
  height: 175px;
  border: 3px dashed #c5c5c5;
  border-radius: 30px;
}

@media screen and (min-width:768px) {
  .section-profile-line {
    width: 40%;
    height: 175px;
    border: 3px dashed #c5c5c5;
    border-radius: 30px;

  }
}


.drop-section-image {
  text-align: center;
  padding-top: 30px;
}

.b-r-0 {
  border-radius: 0px !important;
}

.img-span {
  /* font-family: "Futura Maxi CG Book"; */
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #c5c5c5;
}

.profile-head-section {
  margin-top: 40px;
}

.icons-eye {
  margin-right: 12px;
}

.user-section {
  /* font-family: "Futura Maxi CG Bold"; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
}

.h-90vh {
  height: 90vh !important;
}

.user-section-img {
  /* font-family: "Futura Maxi CG Bold"; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
}

.place-input-profile {
  width: 100%;
  height: 53px;
  margin-top: 10px;
  border: 1px solid var(--borders_clr) !important;
  border-radius: 10px;
  background-color: var(--bg-clr) !important;
  padding: 1rem !important;
}

.place-input-profile::placeholder {
  /* font-family: "Poppins" !important; */
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 126% !important;
  color: #c5c5c5 !important;

}

.profile-line {
  /* font-family: "Futura Maxi CG Book" !important; */
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}

.profile_img {
  background-image: url(./Imagesprofile/profileimg.png);
  background-repeat: no-repeat;
  background-size: contain;


}

.twit {
  margin-left: 20px;
  /* font-family: "Futura Maxi CG Book"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.section-twitter {
  margin-top: 7px;
  display: flex;
}

.connect-bu {
  width: 101px;
  height: 42px;
  border: none;
  background: #fec200;
  border-radius: 10px;
  /* font-family: "Century Gothic" !important; */
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  align-items: center !important;
  color: #101010 !important;
  float: right;
}

.inputgrp {
  width: 100% !important;
  height: 53px;
  background: var(--bg-profile-box-clr);
  border: 1px solid #d9d9d9;
  border-radius: 9.92153px;
}

input.form-control,
span.input-group-text {
  background: var(--bg-profile-box-clr);
}

span.input-group-text {
  background: var(--bg-profile-box-clr);
}

.save-bu-pro {
  width: 118.94px;
  height: 56px;
  left: 314px;
  top: 1156px;
  background: #fec200;
  border-radius: 15px;
  border: none;
  /* font-family: "Century Gothic"; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: #101010;
}

.input-bio {
  width: 262px;
  height: 371px;
  border: none;
  background: var(--bg-profile-box-clr);
  border-radius: 9.92153px;
}

.input-bio::placeholder {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 126%;
  color: #c5c5c5;
  padding: 15px;
}

.profile-section-content {}

.user-bio-section {
  margin-top: 20px;
}

a {
  text-decoration: none !important;
}

@media screen and (min-width:768px) {
  .user-bio-section {
    margin-top: 3.5rem;
  }
}

@media screen and (max-width: 767px) {
  .input-bio {
    width: 100%;
  }

  .place-input-profile {
    /* margin-left: 20px; */
  }

  .section-profile-line {
    /* margin-left: 20px; */
  }

  .profile-head-section {
    /* margin-left: 20px; */
  }

  .profile-section-banner {
    /* margin-left: 20px; */
  }

  .user-section {
    /* margin-left: 20px; */
  }

  .profile-line {
    /* margin-left: 20px; */
  }

  .section-twitter {
    /* margin-left: 20px; */
  }

  .profile-section-content {
    /* margin-left: 20px; */
  }

  .user-bio-section {
    /* margin-left: 20px; */
  }

  .inputgrp {
    /* margin-left: 20px; */
  }

  .save-bu-pro {
    /* margin-left: 20px; */
  }
}

@media screen and (max-width: 410px) {
  .preview-profile-button {
    margin-left: 0px;
  }

  .profile-head-section {
    display: grid;
  }
}