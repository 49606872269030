.background-newsletter {
    background-image: url(../Images/resouces-newsletter-img.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3rem 0rem;
}

.use-section {
    padding-top: 5rem;
}

.containsection {
    background: var(--bg-clr);
    border-radius: 20px;
}

.news-para {
    font-family: 'Futura Maxi CG Book';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 20px;

}

.head-news {
    font-family: 'Futura Maxi CG Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 2rem !important;
    line-height: 35px !important;
    margin-top: 80px;
}

.sectionimg {
    margin-top: 70px;
    width: 100%;
}

.input-email {
    width: 100%;
    height: 53.4px;
    border: 1px solid #D9D9D9;
    border-radius: 9.92153px;
    background-color: transparent;
    color: var(--para-clr) !important;
}

.input-email::placeholder {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 126% !important;
    color: var(--para-clr) !important;
    padding-left: 15px !important;
}

.subs-button {
    margin-top: 10px;
    width: 155px !important;
    height: 56px !important;
    background: #FEC200;
    border-radius: 15px;
    border: none;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #101010;
}

@media screen and (max-width:991px) {
    .head-news {
        margin-top: 0px;
    }

    .sectionimg {
        margin-top: 20px;
    }
}