.collection-font {
    font-family: 'Futura Maxi CG Bold' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    color: #6A6A6A !important;
}

.hhub {
    margin-left: 60px;
}

.hh22 {
    margin-left: 20px;
}



@media screen and (max-width:520px) {
    .collection-head {
        padding-top: 300px;
        margin-left: 24px;
    }

}

@media screen and (max-width:310px) {
    .collection-head {
        padding-top: 330px;
        margin-left: 10px;
        font-size: 31px !important;
    }
}

@media screen and (max-width:981px) {
    .hh22 {
        margin-left: 0px;
    }

    .owner954 {
        font-size: 12px;
    }

    .hhub {
        margin-left: 0px;
    }
}