button#simple-tab-2 {
    font-family: 'Futura Maxi CG Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: var(--para-clr);
}

.filter-button {
    height: 48px !important;
    background: rgba(246, 246, 246, 0.5) !important;
    border-radius: 15px !important;

}

.filter-tab {
    font-family: 'Futura Maxi CG Book' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 126%;
    color: #6A6A6A !important;
    margin-left: 10px;
}

.refresh-button {
    width: 100% !important;
    height: 48px !important;
    background: rgba(246, 246, 246, 0.5) !important;
    border-radius: 15px !important;
    margin-top: 1rem !important;
}

.search-input {
    height: 48px;
    background: rgba(246, 246, 246, 0.5) !important;
    border-radius: 15px !important;
}

.recent-button {
    height: 48px !important;
    background: rgba(246, 246, 246, 0.5) !important;
    border-radius: 15px !important;
}

.view-button {
    width: 92px !important;
    height: 48px !important;
    background: rgba(246, 246, 246, 0.5) !important;
    border-radius: 15px !important;
}

.recent {
    font-family: 'Futura Maxi CG Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 126%;
    color: #6A6A6A;
}

.grid {
    background: white;
    width: 32px;
    border-radius: 5px;
    margin-right: 10px;
    padding: 2px;
    height: 30px;
}

.user-na {
    font-family: 'Futura Maxi CG Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    align-items: center;
    margin-left: -20px !important;
}

.user-na1 {
    font-family: 'Futura Maxi CG Book';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    align-items: center;
    color: var(--border-clr) !important;
    margin-left: -20px !important;
}

.follow-button {
    float: right;
    width: 78.9px;
    height: 35.04px;
    background: #000000;
    border-radius: 10px;
    color: #fff;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

}