.container-fluid.background::before {
  content: '';
  background: url("../Images/homeimage.png") no-repeat center center/cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 35rem;
  min-height: 25rem;
  z-index: -1;
}

.w-37_5 {
  width: 37.5rem !important;
}

.h-25s {
  height: 25rem !important;
}

.o-f-c {
  object-fit: cover !important;
}

.max-w-none {
  max-width: none !important;
}

h1.header {
  text-align: center;
  padding-top: 220px;
  color: #fff;
}

.f-d-c {
  flex-direction: column !important;
}

.m-t-a {
  margin-top: auto !important;
}


.w-20 {}

.j-c-c {
  justify-content: center !important;
}

.g-2 {
  gap: 2rem !important;
}

.bg_slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  margin-bottom: 0 !important;
}

.slick-track {
  margin-left: inherit !important;
  /* margin-top: 5rem !important; */
}

.p-y-15 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.main-button {
  text-align: center;
  padding-top: 45px;
}

img.slider1 {
  width: 100%;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 5%;
  border-radius: 5px;
  background: #8A8A8A;
}

span.swiper-pagination-bullet {
  width: 5%;
  border-radius: 5px;
  background: #C6C6C6;
}

@media screen and (max-width: 1280px) {
  h1.header {
    padding-top: 140px;
  }
}

@media screen and (max-width: 1197px) {
  h1.header {
    padding-top: 110px;
    font-size: 35px !important;
  }
}

@media screen and (max-width: 843px) {
  .main-button {
    margin-top: 15px !important;
  }

}

@media screen and (max-width:600px) {
  h1.header {
    padding-top: 90px;
    font-size: 30px !important;

  }

  .main-button {
    margin-top: -16px !important;
  }
}

@media screen and (max-width:458px) {
  h1.header {
    padding-top: 80px;
    font-size: 25px !important;
  }

}

@media screen and (max-width:414px) {
  h1.header {
    font-size: 20px !important;
  }

  .main-button {
    margin-top: 2px !important;
  }

}

.h-c-v-c {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.ps-r {
  position: relative !important;
}