.icon {
  display: flex;
  list-style-type: none;
  justify-content: start;
}

.icon li {
  margin: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}

.signup {
  font-size: 1.2rem;
  height: 38px;
  width: 130px;
  font-weight: 700;
  color: #fff !important;
  background: #2c6bad !important;
  border: none;
  border-radius: 10px;
  &:hover{
    background: #3d8add !important;
  }
}

.icon li:first-child{
  margin-left: 0 !important;
}

.form-control22 {
  padding: 8px !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
  border: none !important;
  color: #3a3a3a !important;
  font-size: 15px !important;

  margin-right: 15px;
  width: 100%;
}

.twitter-icons {
  background: #2c6bad;
  color: #fff;
  border-radius: 5px;
  padding: 0.5rem 1rem 0.7rem;
  &:hover{
    background: #3d8add !important;
  }
}

.join-header {
  margin-left: 1rem;
  font-size: 1.5rem;
}

.origins-head {
  font-size: 2rem;
  margin: 1rem 0rem;
}

input::placeholder {
  font-size: 1.2rem !important;
}

.input-group {
  width: 55% !important;
  margin: none !important;
}

.footer2-list {
  list-style-type: none;
}

.input-group1 {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 70% !important;
}

ul.footer2-list {
  padding-inline-start: 0px !important;
  margin-bottom: 0px;
}

ul.footer2-list li:hover {
  text-decoration: underline;
  cursor: pointer;
  text-decoration-color: #fec200;
}

ul.footer2-list li {
  margin-top: 0.1rem;
  font-size: 1rem;
}

.keep-head {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.subs-para {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.subs-para,
.input-group1,
.origins-para {
  max-width: 25rem;
}

@media screen and (min-width:1600px) {

  .subs-para,
  .input-group1,
  .origins-para {
    max-width: 47rem;
  }
}

.list-heading {
  margin: 16px 0px;
  font-size: 1.5rem;
  margin-top: 0px;
}

.origins-para {
  font-size: 1rem;
}

.logo-icon {
  width: 3rem;
}

.Footer-left-spacing {
  background-color: #184779;
  color: white;

}

.col-lg-5.col-md-12.col-sm-12.col-12.footer-opensea {
  padding-right: 2rem;
}

@media screen and (max-width: 768px) {

  .list-heading {
    text-align: left;
  }

  h3.join-header {
    margin-left: 0px;
  }
}

@media screen and (max-width: 992px) {
  h5.origins-head {
    text-align: left;
  }

  p.origins-para {
    text-align: left;
  }

  .join-header {
    margin-left: 0px;
  }
}

@media screen and (max-width:912px) {
  h5.origins-head {
    text-align: justify;
  }

  p.origins-para {
    text-align: justify;
  }

  div#foter21 {
    margin-bottom: 0rem;
  }

}

@media screen and (max-width: 512px) {
  .icon {
    padding-inline-start: 0px;

  }
}

.icon {
  padding-inline-start: 0px;
}

@media screen and (max-width: 550px) {
  .icon li {
    margin: 0.4rem;
  }

  .input-group1 {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100% !important;
  }
}

.form-control22::placeholder {
  color: rgb(119, 119, 119) !important;

}
@media screen and (max-width:992px) {
  .footer-section{
    margin-bottom: 3rem;
  }
  .icon li{
    margin: 10px 10px 10px 0px;
  }
  
}

@media screen and (max-width: 1024px) {
  .profile-fo {
    margin-left: 40px;
  }
}

@media screen and (max-width: 768px) {
  .profile-fo {
    margin-left: 1px;
  }
}