.background-profile {
    width: 1440px;
    height: 40vh;
    left: 0.15px;
    top: -1.07px;
}

.box_shadow_hover:hover{
    box-shadow: 0 10px 15px -1px rgba(0, 0, 0, 0.05), 0 4px 6px -1px rgba(0, 0, 0, 0.05) !important;
}

.profileimage {
    margin: auto;
    display: block;
    margin-top: -129px;
    max-width: 15rem;
    height: 15rem;
    border-radius: 50%;
    margin-bottom: 3rem;
}



.v-h{
    visibility: hidden !important;
}
.max-h-80vh {
    max-height: 80vh !important;
}

.scrll::-webkit-scrollbar {
    display: none !important;
}


.profile-detail {
    text-align: center;
}

ul.listed-section {
    padding-inline-start: 0px;
    padding: 1rem;
}

ul.listed-section li {
    padding: 1rem;
}

.edit-button {
    width: 154.7px;
    height: 52.18px;
    left: 578.92px;
    top: 622.78px;
    border: 1px solid var(--borders_clr);
    border-radius: 15px;
    /* font-family: 'Century Gothic'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: var(--para-clr);
    background-color: transparent !important;
}

/* .hover{
    visibility: hidden !important;
  
}
.hover:hover{
    visibility:visible !important;
} */

.z-i-99 {
    z-index: 99;
}

.upload-bu {
    width: 52.18px;
    height: 68.18px;
    left: 749.52px;
    top: 622.78px;
    border: 1px solid #C5C5C5;
    border-radius: 25px;
    padding: 12px 14px;
    margin-left: 10px;
}

.up-bu {
    margin-top: -5px;
}

.edit {
    text-align: center;
    margin-top: 25px !important;
}

.profile-name {
    /* font-family: 'Futura Maxi CG Bold'; */
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 26px;
    text-align: center;
}

.wallet {
    /* font-family: 'Futura Maxi CG Book'; */
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.www {
    /* font-family: 'Futura Maxi CG Book'; */
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

}