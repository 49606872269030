@font-face {
    font-family: "Inter";
    src: url("./Inter-Regular.ttf");
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif !important;

    ul {
        margin: 0 !important;
        padding: 0 !important;
    }

    #dropdown-basic {
        margin: 0 !important;
        padding: 0 !important;
    }

    .dropdown-menu-show {
        margin: 0 !important;
        padding: 0 !important;
    }

    p {
        margin: 0 !important;
        padding: 0 !important;
    }


    img {
        margin: 0 !important;
        padding: 0 !important;
        max-width: 100% !important;
    }

    .MuiGrid-item,
    .MuiGrid-root {
        margin: 0 !important;
    }
}




html {
    font-size: 100% !important;

    background-color: var(--greyLight-1) !important;
}

@media screen and (max-width: 1536px) {
    html {
        font-size: 95% !important;
    }
}

@media screen and (max-width: 1200px) {
    html {
        font-size: 90% !important;
    }
}

@media screen and (max-width: 1050px) {
    html {
        font-size: 85% !important;
    }
}

@media screen and (max-width: 900px) {
    html {
        font-size: 80% !important;
    }
}



.container-kws {
    width: 92.5% !important;
    margin: auto !important;
}





@media screen and (max-width: 900px) {
    .container-kws {
        width: 90% !important;
        margin: auto !important;
    }
}

.m-a {
    margin: auto !important;
}


.ma-0 {
    margin: 0rem !important;
}

.ma-0_5 {
    margin: 0.5rem !important;
}

.ma-1 {
    margin: 1rem !important;
}

.ma-1_5 {
    margin: 1.5rem !important;
}

.ma-2 {
    margin: 2rem !important;
}

.ma-2_5 {
    margin: 2.5rem !important;
}

.ma-3 {
    margin: 3rem !important;
}

.ma-3_5 {
    margin: 3.5rem !important;
}

.ma-4 {
    margin: 4rem !important;
}

.ma-4_5 {
    margin: 4.5rem !important;
}

.ma-5 {
    margin: 5rem !important;
}

.ma-5_5 {
    margin: 5.5rem !important;
}

.ma-6 {
    margin: 6rem !important;
}

.ma-6_5 {
    margin: 6.5rem !important;
}

.ma-7 {
    margin: 7rem !important;
}


.m-l-0 {
    margin-left: 0rem !important;
}

.m-l-0_5 {
    margin-left: 0.5rem !important;
}

.m-l-1 {
    margin-left: 1rem !important;
}

.m-l-1_5 {
    margin-left: 1.5rem !important;
}

.m-l-2 {
    margin-left: 2rem !important;
}

.m-l-2_5 {
    margin-left: 2.5rem !important;
}

.m-l-3 {
    margin-left: 3rem !important;
}

.m-l-3_5 {
    margin-left: 3.5rem !important;
}

.m-l-4 {
    margin-left: 4rem !important;
}

.m-l-4_5 {
    margin-left: 4.5rem !important;
}

.m-l-5 {
    margin-left: 5rem !important;
}

.m-l-5_5 {
    margin-left: 5.5rem !important;
}

.m-l-6 {
    margin-left: 6rem !important;
}

.m-l-6_5 {
    margin-left: 6.5rem !important;
}

.m-l-7 {
    margin-left: 7rem !important;
}


.m-r-0 {
    margin-right: 0rem !important;
}

.m-r-0_5 {
    margin-right: 0.5rem !important;
}

.m-r-1 {
    margin-right: 1rem !important;
}

.m-r-1_5 {
    margin-right: 1.5rem !important;
}

.m-r-2 {
    margin-right: 2rem !important;
}

.m-r-2_5 {
    margin-right: 2.5rem !important;
}

.m-r-3 {
    margin-right: 3rem !important;
}

.m-r-3_5 {
    margin-right: 3.5rem !important;
}

.m-r-4 {
    margin-right: 4rem !important;
}

.m-r-4_5 {
    margin-right: 4.5rem !important;
}

.m-r-5 {
    margin-right: 5rem !important;
}

.m-r-5_5 {
    margin-right: 5.5rem !important;
}

.m-r-6 {
    margin-right: 6rem !important;
}

.m-r-6_5 {
    margin-right: 6.5rem !important;
}

.m-r-7 {
    margin-right: 7rem !important;
}


.m-t-0 {
    margin-top: 0rem !important;
}

.m-t-0_5 {
    margin-top: 0.5rem !important;
}

.m-t-1 {
    margin-top: 1rem !important;
}

.m-t-1_5 {
    margin-top: 1.5rem !important;
}

.m-t-2 {
    margin-top: 2rem !important;
}

.m-t-2_5 {
    margin-top: 2.5rem !important;
}

.m-t-3 {
    margin-top: 3rem !important;
}

.m-t-3_5 {
    margin-top: 3.5rem !important;
}

.m-t-4 {
    margin-top: 4rem !important;
}

.m-t-4_5 {
    margin-top: 4.5rem !important;
}

.m-t-5 {
    margin-top: 5rem !important;
}

.m-t-5_5 {
    margin-top: 5.5rem !important;
}

.m-t-6 {
    margin-top: 6rem !important;
}

.m-t-6_5 {
    margin-top: 6.5rem !important;
}

.m-t-7 {
    margin-top: 7rem !important;
}


.m-b-0 {
    margin-bottom: 0rem !important;
}

.m-b-0_5 {
    margin-bottom: 0.5rem !important;
}

.m-b-1 {
    margin-bottom: 1rem !important;
}

.m-b-1_5 {
    margin-bottom: 1.5rem !important;
}

.m-b-2 {
    margin-bottom: 2rem !important;
}

.m-b-2_5 {
    margin-bottom: 2.5rem !important;
}

.m-b-3 {
    margin-bottom: 3rem !important;
}

.m-b-3_5 {
    margin-bottom: 3.5rem !important;
}

.m-b-4 {
    margin-bottom: 4rem !important;
}

.m-b-4_5 {
    margin-bottom: 4.5rem !important;
}

.m-b-5 {
    margin-bottom: 5rem !important;
}

.m-b-5_5 {
    margin-bottom: 5.5rem !important;
}

.m-b-6 {
    margin-bottom: 6rem !important;
}

.m-b-6_5 {
    margin-bottom: 6.5rem !important;
}

.m-b-7 {
    margin-bottom: 7rem !important;
}

.m-y-0 {
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
}

.m-y-0_5 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
}

.m-y-1 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}

.m-y-1_5 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
}

.m-y-2 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
}

.m-y-2_5 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
}

.m-y-3 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
}

.m-y-3_5 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
}

.m-y-4 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
}

.m-y-4_5 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
}

.m-y-5 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
}

.m-y-5_5 {
    margin-bottom: 5.5rem !important;
    margin-top: 5.5rem !important;
}

.m-y-6 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
}

.m-y-6_5 {
    margin-bottom: 6.5rem !important;
    margin-top: 6.5rem !important;
}

.m-y-7 {
    margin-bottom: 7rem !important;
    margin-top: 7rem !important;
}


.m-x-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
}

.m-x-0_5 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.m-x-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.m-x-1_5 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
}

.m-x-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}

.m-x-2_5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
}

.m-x-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}

.m-x-3_5 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
}

.m-x-4 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
}

.m-x-4_5 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
}

.m-x-5 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
}

.m-x-5_5 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
}

.m-x-6 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
}

.m-x-6_5 {
    margin-left: 6.5rem !important;
    margin-right: 6.5rem !important;
}

.m-x-7 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
}


.p-a {
    padding: auto !important;
}


.pa-0 {
    padding: 0rem !important;
}

.pa-0_5 {
    padding: 0.5rem !important;
}

.pa-1 {
    padding: 1rem !important;
}

.pa-1_5 {
    padding: 1.5rem !important;
}

.pa-2 {
    padding: 2rem !important;
}

.pa-2_5 {
    padding: 2.5rem !important;
}

.pa-3 {
    padding: 3rem !important;
}

.pa-3_5 {
    padding: 3.5rem !important;
}

.pa-4 {
    padding: 4rem !important;
}

.pa-4_5 {
    padding: 4.5rem !important;
}

.pa-5 {
    padding: 5rem !important;
}

.pa-5_5 {
    padding: 5.5rem !important;
}

.pa-6 {
    padding: 6rem !important;
}

.pa-6_5 {
    padding: 6.5rem !important;
}

.pa-7 {
    padding: 7rem !important;
}



.p-b-0 {
    padding-bottom: 0rem !important;
}

.p-b-0_5 {
    padding-bottom: 0.5rem !important;
}

.p-b-1 {
    padding-bottom: 1rem !important;
}

.p-b-1_5 {
    padding-bottom: 1.5rem !important;
}

.p-b-2 {
    padding-bottom: 2rem !important;
}

.p-b-2_5 {
    padding-bottom: 2.5rem !important;
}

.p-b-3 {
    padding-bottom: 3rem !important;
}

.p-b-3_5 {
    padding-bottom: 3.5rem !important;
}

.p-b-4 {
    padding-bottom: 4rem !important;
}

.p-b-4_5 {
    padding-bottom: 4.5rem !important;
}

.p-b-5 {
    padding-bottom: 5rem !important;
}

.p-b-5_5 {
    padding-bottom: 5.5rem !important;
}

.p-b-6 {
    padding-bottom: 6rem !important;
}

.p-b-6_5 {
    padding-bottom: 6.5rem !important;
}

.p-b-7 {
    padding-bottom: 7rem !important;
}


.p-r-0 {
    padding-right: 0rem !important;
}

.p-r-0_5 {
    padding-right: 0.5rem !important;
}

.p-r-1 {
    padding-right: 1rem !important;
}

.p-r-1_5 {
    padding-right: 1.5rem !important;
}

.p-r-2 {
    padding-right: 2rem !important;
}

.p-r-2_5 {
    padding-right: 2.5rem !important;
}

.p-r-3 {
    padding-right: 3rem !important;
}

.p-r-3_5 {
    padding-right: 3.5rem !important;
}

.p-r-4 {
    padding-right: 4rem !important;
}

.p-r-4_5 {
    padding-right: 4.5rem !important;
}

.p-r-5 {
    padding-right: 5rem !important;
}

.p-r-5_5 {
    padding-right: 5.5rem !important;
}

.p-r-6 {
    padding-right: 6rem !important;
}

.p-r-6_5 {
    padding-right: 6.5rem !important;
}

.p-r-7 {
    padding-right: 7rem !important;
}


.p-l-0 {
    padding-left: 0rem !important;
}

.p-l-0_5 {
    padding-left: 0.5rem !important;
}

.p-l-1 {
    padding-left: 1rem !important;
}

.p-l-1_5 {
    padding-left: 1.5rem !important;
}

.p-l-2 {
    padding-left: 2rem !important;
}

.p-l-2_5 {
    padding-left: 2.5rem !important;
}

.p-l-3 {
    padding-left: 3rem !important;
}

.p-l-3_5 {
    padding-left: 3.5rem !important;
}

.p-l-4 {
    padding-left: 4rem !important;
}

.p-l-4_5 {
    padding-left: 4.5rem !important;
}

.p-l-5 {
    padding-left: 5rem !important;
}

.p-l-5_5 {
    padding-left: 5.5rem !important;
}

.p-l-6 {
    padding-left: 6rem !important;
}

.p-l-6_5 {
    padding-left: 6.5rem !important;
}

.p-l-7 {
    padding-left: 7rem !important;
}


.p-t-0 {
    padding-top: 0rem !important;
}

.p-t-0_5 {
    padding-top: 0.5rem !important;
}

.p-t-1 {
    padding-top: 1rem !important;
}

.p-t-1_5 {
    padding-top: 1.5rem !important;
}

.p-t-2 {
    padding-top: 2rem !important;
}

.p-t-2_5 {
    padding-top: 2.5rem !important;
}

.p-t-3 {
    padding-top: 3rem !important;
}

.p-t-3_5 {
    padding-top: 3.5rem !important;
}

.p-t-4 {
    padding-top: 4rem !important;
}

.p-t-4_5 {
    padding-top: 4.5rem !important;
}

.p-t-5 {
    padding-top: 5rem !important;
}

.p-t-5_5 {
    padding-top: 5.5rem !important;
}

.p-t-6 {
    padding-top: 6rem !important;
}

.p-t-6_5 {
    padding-top: 6.5rem !important;
}

.p-t-7 {
    padding-top: 7rem !important;
}


.p-x-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
}

.p-x-0_5 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.p-x-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.p-x-1_5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.p-x-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.p-x-2_5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}

.p-x-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

.p-x-3_5 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
}

.p-x-4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}

.p-x-4_5 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
}

.p-x-5 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}

.p-x-5_5 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
}

.p-x-6 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
}

.p-x-6_5 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
}

.p-x-7 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
}


.p-y-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}

.p-y-0_5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.p-y-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.p-y-1_5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.p-y-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.p-y-2_5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
}

.p-y-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.p-y-3_5 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
}

.p-y-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}

.p-y-4_5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
}

.p-y-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}

.p-y-5_5 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
}

.p-y-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}

.p-y-6_5 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
}

.p-y-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
}


.f-s-0 {
    font-size: 0rem !important;
}

.f-s-0_8 {
    font-size: 0.8rem !important;
}

.f-s-1 {
    font-size: 1rem !important;
}

.f-s-1_25 {
    font-size: 1.25rem !important;
}

.f-s-1_5 {
    font-size: 1.5rem !important;
}

.f-s-2 {
    font-size: 2rem !important;
}

.f-s-2_5 {
    font-size: 2.5rem !important;
}

.f-s-3 {
    font-size: 3rem !important;
}

.f-s-3_5 {
    font-size: 3.5rem !important;
}

.f-s-4 {
    font-size: 4rem !important;
}

.f-s-4_5 {
    font-size: 4.5rem !important;
}

.f-s-5 {
    font-size: 5rem !important;
}

.f-s-5_5 {
    font-size: 5.5rem !important;
}

.f-s-6 {
    font-size: 6rem !important;
}

.f-s-6_5 {
    font-size: 6.5rem !important;
}

.f-s-7 {
    font-size: 7rem !important;
}


.f-w-400 {
    font-weight: 400 !important;
}

.f-w-600 {
    font-weight: 600 !important;
}

.f-w-800 {
    font-weight: 800 !important;
}

.f-w-900 {
    font-weight: 900 !important;
}


.f-s-i {
    font-style: italic !important;
}


.t-a-l {
    text-align: left !important;
}

.t-a-r {
    text-align: right !important;
}

.t-a-c {
    text-align: center !important;
}


.t-d-u {
    text-decoration: underline !important;
}

.t-d-n {
    text-decoration: none !important;
}


.t-t-u {
    text-transform: uppercase !important;
}

.t-t-l {
    text-transform: lowercase !important;
}

.t-t-c {
    text-transform: capitalize !important;
}


.w-0 {
    width: 0rem !important;
}

.w-0_5 {
    width: 0.5rem !important;
}

.w-1 {
    width: 1rem !important;
}

.w-1_5 {
    width: 1.5rem !important;
}

.w-2 {
    width: 2rem !important;
}

.w-2_5 {
    width: 2.5rem !important;
}

.w-3 {
    width: 3rem !important;
}

.w-3_5 {
    width: 3.5rem !important;
}

.w-4 {
    width: 4rem !important;
}

.w-4_5 {
    width: 4.5rem !important;
}

.w-5 {
    width: 5rem !important;
}

.w-5_5 {
    width: 5.5rem !important;
}

.w-6 {
    width: 6rem !important;
}

.w-6_5 {
    width: 6.5rem !important;
}

.w-7 {
    width: 7rem !important;
}

.w-9 {
    width: 9rem !important;
}

.w-11 {
    width: 11rem !important;
}

.w-13 {
    width: 13rem !important;
}

.w-15 {
    width: 15rem !important;
}

.w-17 {
    width: 17rem !important;
}

.w-19 {
    width: 19rem !important;
}




.min-w-0 {
    min-width: 0rem !important;
}

.min-w-0_5 {
    min-width: 0.5rem !important;
}

.min-w-1 {
    min-width: 1rem !important;
}

.min-w-1_5 {
    min-width: 1.5rem !important;
}

.min-w-2 {
    min-width: 2rem !important;
}

.min-w-2_5 {
    min-width: 2.5rem !important;
}

.min-w-3 {
    min-width: 3rem !important;
}

.min-w-3_5 {
    min-width: 3.5rem !important;
}

.min-w-4 {
    min-width: 4rem !important;
}

.min-w-4_5 {
    min-width: 4.5rem !important;
}

.min-w-5 {
    min-width: 5rem !important;
}

.min-w-5_5 {
    min-width: 5.5rem !important;
}

.min-w-6 {
    min-width: 6rem !important;
}

.min-w-6_5 {
    min-width: 6.5rem !important;
}

.min-w-7 {
    min-width: 7rem !important;
}


.max-w-0 {
    max-width: 0rem !important;
}

.max-w-0_5 {
    max-width: 0.5rem !important;
}

.max-w-1 {
    max-width: 1rem !important;
}

.max-w-1_5 {
    max-width: 1.5rem !important;
}

.max-w-2 {
    max-width: 2rem !important;
}

.max-w-2_5 {
    max-width: 2.5rem !important;
}

.max-w-3 {
    max-width: 3rem !important;
}

.max-w-3_5 {
    max-width: 3.5rem !important;
}

.max-w-4 {
    max-width: 4rem !important;
}

.max-w-4_5 {
    max-width: 4.5rem !important;
}

.max-w-5 {
    max-width: 5rem !important;
}

.max-w-5_5 {
    max-width: 5.5rem !important;
}

.max-w-6 {
    max-width: 6rem !important;
}

.max-w-6_5 {
    max-width: 6.5rem !important;
}

.max-w-7 {
    max-width: 7rem !important;
}


.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}


.min-w-25 {
    min-width: 25% !important;
}

.min-w-50 {
    min-width: 50% !important;
}

.min-w-75 {
    min-width: 75% !important;
}

.min-w-100 {
    min-width: 100% !important;
}


.max-w-25 {
    max-width: 25% !important;
}

.max-w-50 {
    max-width: 50% !important;
}

.max-w-75 {
    max-width: 75% !important;
}

.max-w-100 {
    max-width: 100% !important;
}


.h-0 {
    height: 0rem !important;
}

.h-0_5 {
    height: 0.5rem !important;
}

.h-1 {
    height: 1rem !important;
}

.h-1_5 {
    height: 1.5rem !important;
}

.h-2 {
    height: 2rem !important;
}

.h-2_5 {
    height: 2.5rem !important;
}

.h-3 {
    height: 3rem !important;
}

.h-3_5 {
    height: 3.5rem !important;
}

.h-4 {
    height: 4rem !important;
}

.h-4_5 {
    height: 4.5rem !important;
}

.h-5 {
    height: 5rem !important;
}

.h-5_5 {
    height: 5.5rem !important;
}

.h-6 {
    height: 6rem !important;
}

.h-6_5 {
    height: 6.5rem !important;
}

.h-7 {
    height: 7rem !important;
}


.min-h-0 {
    min-height: 0rem !important;
}

.min-h-0_5 {
    min-height: 0.5rem !important;
}

.min-h-1 {
    min-height: 1rem !important;
}

.min-h-1_5 {
    min-height: 1.5rem !important;
}

.min-h-2 {
    min-height: 2rem !important;
}

.min-h-2_5 {
    min-height: 2.5rem !important;
}

.min-h-3 {
    min-height: 3rem !important;
}

.min-h-3_5 {
    min-height: 3.5rem !important;
}

.min-h-4 {
    min-height: 4rem !important;
}

.min-h-4_5 {
    min-height: 4.5rem !important;
}

.min-h-5 {
    min-height: 5rem !important;
}

.min-h-5_5 {
    min-height: 5.5rem !important;
}

.min-h-6 {
    min-height: 6rem !important;
}

.min-h-6_5 {
    min-height: 6.5rem !important;
}

.min-h-7 {
    min-height: 7rem !important;
}


.max-h-0 {
    max-height: 0rem !important;
}

.max-h-0_5 {
    max-height: 0.5rem !important;
}

.max-h-1 {
    max-height: 1rem !important;
}

.max-h-1_5 {
    max-height: 1.5rem !important;
}

.max-h-2 {
    max-height: 2rem !important;
}

.max-h-2_5 {
    max-height: 2.5rem !important;
}

.max-h-3 {
    max-height: 3rem !important;
}

.max-h-3_5 {
    max-height: 3.5rem !important;
}

.max-h-4 {
    max-height: 4rem !important;
}

.max-h-4_5 {
    max-height: 4.5rem !important;
}

.max-h-5 {
    max-height: 5rem !important;
}

.max-h-5_5 {
    max-height: 5.5rem !important;
}

.max-h-6 {
    max-height: 6rem !important;
}

.max-h-6_5 {
    max-height: 6.5rem !important;
}

.max-h-7 {
    max-height: 7rem !important;
}


.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}


.min-h-25 {
    min-height: 25% !important;
}

.min-h-50 {
    min-height: 50% !important;
}

.min-h-75 {
    min-height: 75% !important;
}

.min-h-100 {
    min-height: 100% !important;
}


.max-h-25 {
    max-height: 25% !important;
}

.max-h-50 {
    max-height: 50% !important;
}

.max-h-75 {
    max-height: 75% !important;
}

.max-h-100 {
    max-height: 100% !important;
}


.b-r-5 {
    border-radius: 5px !important;
}

.b-r-10 {
    border-radius: 10px !important;
}

.b-r-15 {
    border-radius: 15px !important;
}

.b-r-20 {
    border-radius: 20px !important;
}

.b-r-3 {
    border-radius: 3rem !important;
}


.b-r-50 {
    border-radius: 50% !important;
}


.b-1 {
    border: 1px solid !important;
}

.b-2 {
    border: 2px solid !important;
}

.b-3 {
    border: 3px solid !important;
}

.b-n {
    border: none !important;
}


a {
    color: inherit !important;
    text-decoration: none !important;

    &:hover {
        color: inherit !important;
        text-decoration: none !important;
    }
}


.f-l {
    float: left !important;
}

.f-r {
    float: right !important;
}


.ps-r {
    position: relative !important;
}


.ps-a {
    position: absolute !important;
}

.h-c-v-c {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}


.ps-f {
    position: fixed !important;
}


.ps-s {
    position: sticky !important;
}


.d-f {
    display: flex !important;
}

.d-i {
    display: inline !important;
}

.d-i-b {
    display: inline-block !important;
}

.d-b {
    display: block !important;
}

.d-g {
    display: grid !important;
}

.d-n {
    display: none !important;
}


.j-c-s-b {
    justify-content: space-between !important;
}

.j-c-s-a {
    justify-content: space-around !important;
}

.j-c-s-e {
    justify-content: space-evenly !important;
}


.l-s-t-n {
    list-style-type: none !important;
}

.l-s-t-c {
    list-style-type: circle !important;
}


.o-25 {
    opacity: 0.25 !important;
}

.o-50 {
    opacity: 0.50 !important;
}


.o-75 {
    opacity: 0.75 !important;
}


.o-1 {
    opacity: 1 !important;
}


.o-h {
    overflow: hidden !important;
}

.o-a {
    overflow: auto !important;
}

.o-s {
    overflow: scroll !important;
}

.o-x-h {
    overflow-x: hidden !important;
}

.o-x-a {
    overflow-x: auto !important;
}

.o-x-s {
    overflow-x: scroll !important;
}

.o-y-h {
    overflow-y: hidden !important;
}

.o-y-a {
    overflow-y: auto !important;
}

.o-y-s {
    overflow-y: scroll !important;
}


.p-i-s-0 {
    padding-inline-start: 0rem !important;
}

.p-i-s-0_5 {
    padding-inline-start: 0.5rem !important;
}

.p-i-s-1 {
    padding-inline-start: 1rem !important;
}


.c-n-d {
    cursor: no-drop !important;
}

.c-p {
    cursor: pointer !important;
}


.b-c-t {
    background-color: transparent !important;
}


.c-i {
    color: inherit !important;
}


.section {
    margin: 8rem 0rem !important;
}

.section_home {
    margin: 12rem 0rem !important;
}

@media screen and (max-width:1200px) {
    .section_home {
        margin: 8rem 0rem !important;
    }
}


.b-c-i {
    border-color: inherit !important;
}


.c-w {
    color: white !important;
}

.c-b {
    color: black !important;
}

.c-g {
    color: #74788d;
}


.b-c-w {
    background-color: white !important;
}

.b-c-b {
    background-color: black !important;
}


.w-w-b-w {
    word-wrap: break-word !important;
}

.w-b-b-w {
    word-break: break-word !important;
}


.a-i-c {
    align-items: center !important;
}

.b-r-3 {
    border-radius: 3rem !important;
}

.b-r-1 {
    border-radius: 1rem !important;
}

.v-w-1 {
    font-size: 1vw !important;
}

.v-w-2 {
    font-size: 2vw !important;
}

.v-w-3 {
    font-size: 3vw !important;
}

.v-w-4 {
    font-size: 4vw !important;
}

.v-w-5 {
    font-size: 5vw !important;
}

.v-w-6 {
    font-size: 6vw !important;
}

.v-w-7 {
    font-size: 7vw !important;
}

.v-w-8 {
    font-size: 8vw !important;
}

.v-w-9 {
    font-size: 9vw !important;
}

.max-h-33 {
    max-height: 33rem !important;
}


@media screen and (max-width:1200px) {
    .m-q-b-d-b {
        display: block !important;
    }
}

.b-r-t-l-r-1 {
    border-radius: 1rem 1rem 0rem 0rem !important;
}

.h-16 {
    height: 16rem !important;
}

.max-w-15 {
    max-width: 15rem !important;
}

.max-w-20 {
    max-width: 20rem !important;
}

.max-w-30 {
    max-width: 30rem !important;
}


.max-h-50vh {
    max-height: 50vh !important;
}

.t-a-j {
    text-align: justify !important;
}

.m-t-50vh {
    margin-top: 50vh !important;
}

.w-21 {
    width: 21rem !important;
}

.gray-clr {
    color: gray !important;
}

.max-w-50 {
    max-width: 50rem !important;
}

.min-h-30 {
    min-height: 30rem !important;
}

.h-20 {
    height: 20rem !important;
}

.h-15 {
    height: 15rem !important;
}

.w-b-b-a {
    word-break: break-all !important;
}

.t-0 {
    top: 0 !important;
}

.l-0 {
    left: 0 !important;
}

.h-100vh {
    height: 100vh !important;
}

.min-h-90vh {
    min-height: 90vh !important;
}

.m-b-12 {
    margin-bottom: 12rem !important;
}

.z-i-999 {
    z-index: 999 !important;
}

.v-l {
    border-right: 1px solid;
}


@media screen and (min-width:1200px) {
    .m-q-a-d-n-v-l {
        border-right: 1px solid;
    }
}

.f-s-0_5 {
    font-size: 0.5rem !important;
}

.f-s-0_7 {
    font-size: 0.7rem !important;
}

.f-s-0_6 {
    font-size: 0.6rem !important;
}

.w-20 {
    width: 20rem !important;
}

.w-35 {
    width: 35rem !important;
}

.w-s-n-w {
    white-space: nowrap;
}

.f-w {
    fill: white !important;
}

button.menu-button-mobile.m-l-1.btn.btn-primary,
button.menu-button-mobile.m-l-1.btn.btn-primary:hover {
    background-color: transparent !important;
    color: black !important;
    border: none;
    font-size: 1.5rem;
}

.primary-button {
    padding: 0.5rem 2rem !important;
    background-color: transparent !important;
    color: inherit !important;
    float: right;
    margin-top: 2rem !important;
}

img.safu-img {
    width: 250px;
    margin: 50px 30px 20px;
}

.min-h-100vh {
    min-height: 100vh !important;
}

.w-45 {
    width: 45rem !important;
}

img.game-img {
    width: 250px;
    display: block;
    margin-right: auto;
}

.profile23 {
    background-color: var(--bg-box-clr-2);
    position: absolute !important;
    top: 85%;
    right: 0%;
    z-index: 9999999 !important;
    border-radius: 10px;
    overflow: hidden !important;
    padding: 0rem;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.383);
    color: var(--para-clr);


}

#navbar23 {
    background-color: #00000078;
}

.slider-container {
    margin-top: 112px;
    width: 100%;
    border-radius: 20px;
}

button.enter-button {
    width: 170px;
    height: 60px;
    font-weight: 700;
    margin-left: 30px;
    display: block;
    margin-bottom: 20px;
    border-radius: 20px;
    border: none;
}

img.slider1 {
    padding: 0.5rem;
    width: 100%;
    height: 20rem;
    border-radius: 20px;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 20px;
    padding: 0;
    cursor: pointer !important;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 5px;
    border-radius: 25px;
    background-color: var(--para-clr);
    content: "";
    text-align: center;
    margin-top: 20px;
}

.slick-prev:before,
.slick-next:before {
    opacity: 1 !important;
}

.slick-prev:before,
.slick-next:before {
    color: #fec200 !important;

    font-size: 2.5rem;
}

.slick-prev,
.slick-next {
    z-index: 1 !important;
}

@media screen and(max-width:912px) {
    .slider-container {
        width: 100%;
    }

    img.safu-img {
        width: 250px;
        margin: 50px 30px 20px;
    }

    img.game-img {
        width: 250px;
        display: block;
        margin: auto;
    }
}

@media screen and(max-width:480px) {
    .slider-container {
        width: 100%;
    }
}

.search_bar {
    border-radius: 12px;
    overflow: hidden !important;
    border-width: 1px;
    /* border-style: solid;
    border-color: var(--); */
    background-color: rgba(59, 59, 59, 0.459) !important;
    color: rgb(255, 255, 255);
    backdrop-filter: blur(20px);
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
        border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
}


.search {
    border-radius: 12px;
    border-width: 1px;
    /* border-style: solid;
    border-color: var(--); */
    background-color: rgba(255, 255, 255, 0.12);
    color: rgb(255, 255, 255);
    backdrop-filter: blur(20px);
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
        border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
}


.search_text::placeholder {
    color: white !important;
}

.search_text:focus {
    outline: none !important;
}

.b-right_1 {
    border-right: 1px solid rgba(255, 255, 255, 0.699) !important;

    &:hover {
        border-right: 1px solid rgba(255, 255, 255, 0.699) !important;
    }
}

.modal-content {
    border: none !important;
}

.wallet {

    display: inline-flex;
    padding: 0.5rem 0rem 0.5rem 0.5rem;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: transparent;
    background-color: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(20px);
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
        border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
    color: rgb(255, 255, 255);
    border: none !important;

    &:hover {
        border-right-width: 1px;
        border-color: transparent rgba(255, 255, 255, 0.2) transparent transparent;
        background-color: rgba(255, 255, 255, 0.2);
    }
}

.wallet_global {
    display: inline-flex;
    /* padding: 0.5rem 0rem 0.5rem 0.5rem; */
    padding: 12px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: transparent;
    background-color: var(--border_clrs);
    backdrop-filter: blur(20px);
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
        border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
    /* color: rgb(255, 255, 255); */

    &:hover {
        border-right-width: 1px;
        border-color: transparent rgba(255, 255, 255, 0.2) transparent transparent;
        background-color: var(--border_hover_clrs);
    }
}

.wallet_global_ck {
    display: inline-flex;
    /* padding: 0.5rem 0rem 0.5rem 0.5rem; */
    padding: 12px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: transparent;
    background-color: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(20px);
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
        border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
    /* color: rgb(255, 255, 255); */

    &:hover {
        border-right-width: 1px;
        border-color: transparent rgba(255, 255, 255, 0.2) transparent transparent;
        background-color: rgba(255, 255, 255, 0.2);
    }
}

.profile {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background-color: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(20px);
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
        border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
    color: rgb(255, 255, 255);
    padding: 0.5rem 1rem !important;
    border: none !important;

    &:hover {
        border-color: transparent rgba(255, 255, 255, 0.2) transparent transparent;
        background-color: rgba(255, 255, 255, 0.2);

        .b-right_1 {
            border-right: 1px solid rgba(255, 255, 255, 0.699) !important;
        }
    }
}

.profile_global {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    border: 1px solid var(--border-clrs) !important;
    background-color: var(--border_clrs);
    backdrop-filter: blur(20px);
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
        border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
    /* color: rgb(255, 255, 255); */
    padding: 10px;

    &:hover {
        border-right-width: 1px;
        border-color: transparent rgba(255, 255, 255, 0.2) transparent transparent;
        background-color: var(--border_hover_clrs);

        .b-right_1 {
            border-right: 1px solid rgba(255, 255, 255, 0.699) !important;
        }
    }
}

.profile_global_ck {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    border: 1px solid var(--border-clrs) !important;
    background-color: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(20px);
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
        border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
    /* color: rgb(255, 255, 255); */
    /* padding: 9px; */

    &:hover {
        border-right-width: 1px;
        border-color: transparent rgba(255, 255, 255, 0.2) transparent transparent;
        background-color: rgba(255, 255, 255, 0.2);

        .b-right_1 {
            border-right: 1px solid rgba(255, 255, 255, 0.699) !important;
        }
    }
}

.search:hover {
    border-color: transparent;
    background-color: rgba(255, 255, 255, 0.2);
    transition: box-shadow 0.25s ease-in-out 0s, border-color 0.25s ease-in-out 0s;
}

.search_text {
    color: white !important;
}

input:focus {
    /* border: none !important; */
    outline: none !important;
}

.slash {
    height: 26px;
    min-width: 26px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    background-color: rgba(255, 255, 255, 0.16);
    cursor: default;
}


.slash_global {
    height: 26px;
    min-width: 26px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    background-color: rgb(171 171 171 / 16%);
    cursor: default;
}

.slick-active button {
    color: #000000 !important;
}

.slick-dots li.slick-active button:before {
    color: #48ff7b !important;
    background-color: var(--para-clr) !important;
}

#enter-12 {
    color: black !important;
}

.slick-prev {
    left: -3px !important;
}

.slick-next {
    right: 15px;
}

@media screen and (max-width: 768px) {
    .slick-prev {
        left: -3px !important;
    }

    .slick-next {
        right: 5px;
    }
}

img.card-img-top {
    border-radius: 15px 15px 0px 0px !important;
}

.text32 {
    font-size: 0.8rem !important;
}


.notable_card {
    background-color: var(--card_bg);
    border-radius: 0px 0px 12px 12px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 16px;
    transition: box-shadow 0.25s ease-in-out 0s, transform 0.25s ease 0s;
}


.notable_card_img {
    background-color: var(--card_bg);
    border-radius: 12px 12px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 16px;
    transition: box-shadow 0.25s ease-in-out 0s, transform 0.25s ease 0s;
}

.bt_br {
    border-radius: 20px 20px 0px 0px;
}

.w-27 {
    width: 27rem !important;
}


.w-1_25 {
    width: 1.25rem !important;
}

.bg_grays{
background-color: #f4f4f4 !important;
}