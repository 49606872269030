button#simple-tab-2 {
  font-family: "Futura Maxi CG Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 24px !important;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: var(--para-clr);
}

.filter-button {
  height: 48px !important;
  background: rgba(246, 246, 246, 0.5) !important;
  border-radius: 15px !important;
  color: #6A6A6A !important;
}

#form-search-input {
  border: none !important;
  background: rgba(246, 246, 246, 0.5) !important;
  border-radius: 15px !important;
}

/* .search-text-field {
  margin-top: 0.5rem !important;
} */

#search-icon-text {
  color: var(--para-clr) !important;
}

.m-q-b-d-b {
  display: flex !important;
}

.m-q-w-15 {
  width: 17rem !important;
}

@media screen and (max-width:900px) {
  .m-q-b-d-b {
    display: block !important;
  }

  .m-q-w-15 {
    width: 100% !important;
  }
}


.h-10 {
  height: 10rem !important;
}

.border_rad {
  border-radius: 12px 12px 0px 0px !important;
}

#search-icon {
  fill: #6A6A6A !important;
  font-size: 1.8rem;
}

.input-nav {
  display: flex;
  height: 3rem;
}

p.explore-para {
  padding-left: 2px;
}

.filter-tab {
  font-family: "Futura Maxi CG Book" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 126%;
  color: #6a6a6a !important;
  margin-left: 10px;
}

form.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1vm0r4s-MuiPaper-root {
  width: 100%;
  /* margin-top: 8px; */
}

.refresh-button {
  width: 46px !important;
  height: 48px !important;
  background: rgba(246, 246, 246, 0.5) !important;
  border-radius: 15px !important;
}

.search-input {
  height: 48px;
  background: rgba(246, 246, 246, 0.5) !important;
  border-radius: 15px !important;
}

.recent-button {

  height: 48px !important;
  background: rgba(246, 246, 246, 0.5) !important;
  border-radius: 15px !important;
}

.view-button {
  width: 92px !important;
  height: 48px !important;
  background: rgba(246, 246, 246, 0.5) !important;
  border-radius: 15px !important;
}

.recent {
  font-family: "Futura Maxi CG Book";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 126%;
  color: #6a6a6a;
}

.grid {
  background: white;
  width: 32px;
  border-radius: 5px;
  margin-right: 10px;
  padding: 2px;
  height: 30px;
}

.css-1nx0jaf {
  width: 0px !important;
}

.card {
  margin: 0.5rem;
  /* border-radius: 1rem !important; */
  box-shadow: 0px 0px 2px var(--box-shadow-clr);
}

.card-title {
  font-family: 'Futura Maxi CG Book';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  color: var(--para-clr);
}

.card-text {
  /* font-family: 'Futura Maxi CG Bold' !important; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 17px;
  align-items: center;
  color: var(--para-clr) !important;
}

.explore-nft-card-price {
  display: flex;
  justify-content: space-between;
  background-color: var(--background-nft-card);
  padding: 1rem;
  border-radius: 10px;
}

.explore-collection-card-price {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem 0rem;
  border-radius: 10px;
}

.price-exlore-nft {
  font-size: 10px;
  font-family: 'Futura Maxi CG Bold';

}

.css-19kzrtu {
  padding: 0rem !important;
}

.search-input {
  width: 100%;
  min-width: 10rem;
  margin: 1rem;
  padding: 1rem;
}

@media screen and (min-width:1200px) {
  .filter-button {

    margin: 1rem !important;
  }

  .recent-button {

    margin: 1rem !important;
  }
}

.m-q-h-10 {
  height: 12rem !important;
}

@media screen and (max-width:1200px) {

  .filter-button,
  .recent-button {

    margin: 1rem 1rem 1rem 0rem !important;
  }

  .m-q-h-10 {
    height: 18rem !important;
  }

}

.filter-button,
.refresh-button {
  width: 100% !important;
}