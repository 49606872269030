.brow {
  padding-top: 2rem;
}

.browse32 {
  padding-bottom: 5rem !important;
}

#browse23 {
  padding-bottom: 5rem !important;
}

.cards {
  width: 90%;
}

.b-r-1 {
  border-right: 1px solid;
}

.min-h-80vh {
  min-height: 80vh !important;
}

.d-g {
  display: grid !important;
}

.g-t-c-200px {
  grid-template-columns: 200px auto !important;
}

@media screen and (max-width:900px) {
  .g-t-c-200px {
    grid-template-columns: 1fr !important;
  }
}

.modal-content,
input,
select {
  background-color: var(--bg-clr) !important;
  color: var(--para-clr) !important;
  /* border-color: var(--bd_clrs) !important; */
}

input {
  background-color: transparent !important;
}


.card-img-top.card-img-top1 {
  height: 100% !important;
}

.card-body1 {
  height: 20%;
}

.cards1 {
  width: 100%;
  position: relative !important;
  background-color: #b4cb7d !important;
}

.cards2 {
  width: 100%;
  position: relative !important;
  background-color: #ffe97e !important;
}

.cards3 {
  width: 100%;
  position: relative !important;
  background-color: #d99aff !important;
}

.cards4 {
  width: 100%;
  position: relative !important;
  background-color: #94f2ff !important;
}

.cards5 {
  width: 100%;
  position: relative !important;
  background-color: #ffc2af !important;
}

.cards6 {
  width: 100%;
  position: relative !important;
  background-color: #b6ffe9 !important;
}

.card-img-top1 {
  height: 20rem !important;
}

.card-body1 {
  position: absolute !important;
  width: 100% !important;
  bottom: 0 !important;
  background-image: linear-gradient(rgba(188, 188, 188, 0.7) 0%,
      rgba(71, 71, 72, 0.4) 100%);
}


.card-title1 {
  color: white !important;
  font-size: 2rem !important;
  font-weight: 900 !important;
  font-family: "Futura Maxi CG Bold";
}


/* RESPONSIVE */
@media screen and (max-width: 768px) {
  .card-title1 {
    margin-top: -15px !important;
    font-size: 1.4rem !important;
  }
}

@media screen and (max-width: 840px) {
  .card-title1 {
    margin-top: -15px !important;
    font-size: 1.4rem !important;
  }
}

@media screen and (max-width: 912px) {
  .card-title1 {
    margin-top: -8px !important;
    font-size: 1.4rem !important;
  }
}

.f-s-1 {
  font-size: 1rem !important;
}

.c-p {
  cursor: pointer !important;
}